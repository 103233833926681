<div class="d-flex justify-content-center mt-4">
    <div class="row col-md-10">
        <div routerLink="/admin/admins" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faUsers" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.admins" | translate }} </div>
        </div>
        <!--div routerLink="/user" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faUsers" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.users" | translate }}</div>
        </div-->
        <div routerLink="/merchant" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faHome" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.merchants" | translate }}</div>
        </div>
        <div routerLink="/notification" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faEnvelopeOpenText" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.notification" | translate }}</div>
        </div>
        <div routerLink="/category" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faClipboard" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.categories" | translate }}</div>
        </div>
        <div routerLink="/subcategory" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faClipboardList" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.subcategories" | translate }}</div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-center mt-4">
    <div class="row col-md-10">
        <div routerLink="/tag" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faTag" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.tags" | translate }}</div>
        </div>
        <div routerLink="/sales-agent" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faUser" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.sales-agent" | translate }}</div>
        </div>
        <div routerLink="/sponsor" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faIndustry" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.sponsor" | translate }}</div>
        </div>
        <div routerLink="/merchant/report" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faFileExcel" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.merchants_report" | translate }}</div>
        </div>
        <div routerLink="/customer/report" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon bg-admin-icon">
                <fa-icon [icon]="faFileExcel" class="mr-2 h1"></fa-icon>
            </div>
            <div class="btn-admin-label bg-strapp"> {{ "admin.customers_report" | translate }}</div>
        </div>
    </div>
</div>

