import { Component, OnInit } from '@angular/core';
import { FormComponent } from '../../../shared/form/form.component';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { BeaconService } from '../beacon.service';
import { Validators } from '@angular/forms';
import { LocationService } from '../location.service';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-beacon-form',
  templateUrl: './beacon-form.component.html',
  styleUrls: ['./beacon-form.component.css']
})
export class BeaconFormComponent extends FormComponent implements OnInit {
  faHome = faHome;
  faCalendarAlt = faCalendarAlt;
  merchantId;
  merchant;
  locations =[];
  
  constructor(
    public service: BeaconService,
    public serviceLocation: LocationService) {
      super(service);
    }

  ngOnInit() {
    this.merchantId = this.route.firstChild.snapshot.paramMap.get('idMerchant');
    super.ngOnInit();
    this.redirect = ['/setup/' + this.merchantId+'/bea'];
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      model: ['', Validators.required],
      serial: ['', Validators.required],
      region: ['',[Validators.required]],
      major: ['', [Validators.required]],
      minor: ['', Validators.required],
      ble_mac: [''],
      location: ['', Validators.required],
      sensitivity: ['', Validators.required],
      status: ['1', Validators.required],
    });
  }

  getExtras(){
    this.serviceLocation.getdd(this.merchantId).subscribe( (response) => {
      this.locations = response;
    }, (error) => {
      this.alertify.error('No fue posible obtener las localizaciones');
    });
  }

  dataToForm(data) {
    return {
      name: data.name,
      model: data.model,
      serial: data.serial_number,
      region: data.ibeacon_region,
      major: data.ibeacon_major,
      minor: data.ibeacon_minor,
      ble_mac: data.ble_mac,
      location: data.location_id,
      sensitivity: data.sensitivity_level,
      status: ''+data.status,
    };
  }
  
  formToData() {
    this.data = {
      name: this.f.name.value,
      model: this.f.model.value,
      serial_number: this.f.serial.value,
      ibeacon_region: this.f.region.value,
      ibeacon_major: this.f.major.value,
      ibeacon_minor: this.f.minor.value,
      ble_mac: this.f.ble_mac.value,
      location_id: this.f.location.value,
      sensitivity_level: this.f.sensitivity.value,
      status: this.f.status.value,
      merchant_id: this.merchantId
    };
  }

  save() {
    this.loading = true;
    this.service.save(this.data).subscribe((response) => {
      this.loading = false;
      this.alertify.success(this.translate.instant('general.form.success_save'));
      this.router.navigate(this.redirect);
    }, (error) => {
      this.loading = false;
      if(error.status == 400 && error.error.message == 'Beacon key repeated'){
        this.alertify.error(this.translate.instant('beacon.errors.repeated_key'));
      }else{
        this.alertify.error(this.translate.instant('general.error'));
      }
    });
  }

  update() {
    this.loading = true;
    this.data.id = this.currentID;
    this.service.update(this.data).subscribe((response) => {
      this.loading = false;
      this.alertify.success(this.translate.instant('general.form.success_update'));
      this.router.navigate(this.redirect);
    }, (error) => {
      this.loading = false;
      if(error.status == 400 && error.error.message == 'Beacon key repeated'){
        this.alertify.error(this.translate.instant('beacon.errors.repeated_key'));
      }else{
        this.alertify.error(this.translate.instant('general.error'));
      }
      
    });
  }

}
