import { Component, OnInit } from '@angular/core';
import { FormComponent } from '../../../shared/form/form.component';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { RewardService } from '../reward.service';
import { MerchantService } from '../../merchant.service';
import { Validators } from '@angular/forms';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { faCloudDownloadAlt, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { LocationService } from '../location.service';
import { AuthService } from 'src/app/auth-module/auth.service';

@Component({
  selector: 'app-reward-form',
  templateUrl: './reward-form.component.html',
  styleUrls: ['./reward-form.component.css']
})
export class RewardFormComponent extends FormComponent implements OnInit {

  faCalendarAlt = faCalendarAlt;
  faHome = faHome;
  locationsSelected =[];
  locations =[];
  merchantId;
  merchant;
  role;


  image;
  urlImage: any;
  loading = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  fileToUpload: File;
  imgResultBeforeCompress:string;
  imgResultAfterCompress:string;
  faTrash = faTrash;
  faCloudDownload= faCloudDownloadAlt;

  redirectTest;

  constructor(
    public service: RewardService,
    public serviceLocation: LocationService,
    public serviceMerchant: MerchantService,
    public imageCompress: NgxImageCompressService,
    public auth: AuthService) {
      super(service);

      this.role = this.auth.currentUserValue.user.role;

      if(this.auth.currentUserValue.user.role == 'Merchant'){
        this.merchantId = this.auth.currentUserValue.user.merchant.id;
      }else{
        this.merchantId = this.route.firstChild.snapshot.paramMap.get('idMerchant');
      }
    }

  ngOnInit() {
    super.ngOnInit();

    if(this.auth.currentUserValue.user.role == 'Merchant'){
      this.redirect = ['/setup/rew'];
		}else{
      this.redirect = ['/setup/' + this.merchantId+'/rew'];
		}
    
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      subtitle: ['', Validators.required],
      description: ['', Validators.required],
      start: ['', Validators.required],
      stop: ['', Validators.required],
      punches: ['', [Validators.required]],
      all_locations: ['0', Validators.required],
      status: ['1', Validators.required],
    });
  }

  getExtras(){
    this.serviceMerchant.get(this.merchantId).subscribe( (response) => {
      this.merchant = response;
    }, (error) => {
      this.alertify.error('No fue posible obtener el comerciante');
    });
    this.serviceLocation.getdd(this.merchantId).subscribe( (response) => {
      this.locations = response;
      this.hasCheck();
    }, (error) => {
      this.alertify.error('No fue posible obtener las localizaciones');
    });
  }

  dataToForm(data) {
    return {
      title: data.title,
      subtitle: data.subtitle,
      description: data.description,
      start: this.stringToDate(data.reward_start),
      stop: this.stringToDate(data.reward_stop),
      punches: data.punch_count,
      status: ''+data.status,
      all_locations: ''+data.all_locations
    };
  }

  formToData() {
    const formData = new FormData;

    formData.append('title', this.f.title.value);
    formData.append('subtitle', this.f.subtitle.value);
    formData.append('password', this.f.password ? this.f.password.value : null);
    formData.append('description', this.f.description.value);
    formData.append('reward_start', this.dateToString(this.f.start.value));
    formData.append('reward_stop', this.dateToString(this.f.stop.value));
    formData.append('punch_count', this.f.punches.value);
    formData.append('image_url', this.urlImage);
    formData.append('status', this.f.status.value);
    formData.append('merchant_id', this.merchantId);
    formData.append('locations', this.f.all_locations.value == 0 ? JSON.stringify(this.locationsSelected) :JSON.stringify([]));
    formData.append('all_locations', this.f.all_locations.value);
    

    this.data = formData;

  }
  
  fileChangeEvent(event: any): void {
    const FileSize = event.target.files.item(0).size / 1024 / 1024; // in MB
    if (FileSize > 10) {
      event.value = '';
      this.alertify.error('El tamaño del archivo excede los 10 MB');
      return;
    }
    this.fileToUpload = event.target.files.item(0);
  }

  validCheck(ev){ 
    let val = parseInt(ev.currentTarget.defaultValue);
    if(ev.currentTarget.checked){
      this.locationsSelected.push(val);
    }else{
      this.locationsSelected = this.arrayRemove(this.locationsSelected, val);
    }
  }


  arrayRemove(arr, value) {   
    return arr.filter(function(ele){ 
        return ele != value; 
    });
  }

  hasCheck(){
    if(this.data && this.data.locations){
      this.locations.forEach(locg => {
        this.data.locations.forEach(locd => {
           if(locd.id === locg.id){
            locg.checked = true;
              if(!this.locationsSelected.includes(locg.id)){
                this.locationsSelected.push(locg.id);
              }
           }
        });
      });
    }
  }

}
