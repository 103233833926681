import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FormComponent } from 'src/app/shared/form/form.component';
import { SalesAgentService } from '../sales-agent.service';

@Component({
  selector: 'app-sales-agent-form',
  templateUrl: './sales-agent-form.component.html',
  styleUrls: ['./sales-agent-form.component.css']
})
export class SalesAgentFormComponent extends FormComponent implements OnInit {
  faUser = faUser;
  constructor(
    public service: SalesAgentService) {
      super(service);
    }

    ngOnInit() {
      super.ngOnInit();
      this.redirect = ['/sales-agent'];

      this.form = this.formBuilder.group({
        name: ['', [Validators.required,Validators.maxLength(255)]],
        telephone: ['', [Validators.maxLength(20)]],
        email: ['', [Validators.email]],
      });  
    }

    dataToForm(data) {
      return {
        name: data.name,
        telephone: data.telephone,
        email: data.email,
      };
    }
  
    formToData() {
      this.data = {
        name: this.f.name.value,
        telephone: this.f.telephone.value,
        email: this.f.email.value,
      };
    }

}
