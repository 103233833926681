import { Component, OnInit, Input, NgZone } from '@angular/core';
import { AG_GRID_LOCALE_EN } from '../../../shared/list/locale/en.locale';
import { ListComponent } from '../../../shared/list/list.component';
import { LocationService } from '../location.service';
import { ListService } from '../../../shared/list-service.service';
import { ActionsCellRenderer } from '../../../shared/list/renderer/actions-cell.renderer';
import { DecimalPipe } from '@angular/common';
import { AuthService } from 'src/app/auth-module/auth.service';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.css'],
  providers: [ListService, DecimalPipe]
})
export class LocationListComponent extends ListComponent implements OnInit {

  	private gridApi;
	public gridOptions: any;
	public gridLocale;
	@Input() visible: boolean;

	constructor(
		private zone: NgZone,
		public service: LocationService,
		public listService: ListService,
		public auth: AuthService) {
		super(service, listService);
		this.setObject('location');
	}

	ngOnInit() {
		if(this.auth.currentUserValue.user.role == 'Merchant'){
			this.service.merchantId = this.auth.currentUserValue.user.merchant.id;
		}else{
			this.service.merchantId = this.route.firstChild.snapshot.paramMap.get('idMerchant');
		}
		
		this.gridLocale = AG_GRID_LOCALE_EN;
		this.gridOptions = {
			columnDefs: [
				{ headerName: '#', field: 'id', sortable: true, width: 60 },
				{ headerName: 'location.form.name', field: 'name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'location.form.email', field: 'email', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'location.form.phone', field: 'telephone', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
					headerName: 'location.form.status',
					field: 'status',
					sortable: true,
					cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('location.active');
						} else {
							return this.translate.instant('location.inactive');
						}
					},
					headerValueGetter: this.localizeHeader.bind(this)
				},
				{
					headerName: 'list.actions',
					field: 'id',
					cellRenderer: 'actionsCellRenderer',
					cellRendererParams: {
						actions: [
							{
								icon: 'ver_active2x.png',
								action: (id) => {
									this.zone.run(() => {
										this.show(id);
									});
								}
							},
							{
								icon: 'icon_editar2x.png',
								action: (id) => {
									this.zone.run(() => {
										this.edit(id);
									});
								}
							},
							{
								icon: 'icon_borrar2x.png',
								action: (id) => {
									this.delete(id);
								}
							}
						]
					},
          			headerValueGetter: this.localizeHeader.bind(this)
				}
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

	show(id){
		this.router.navigate(['/'+this.object + '/'+this.service.merchantId +'/view/'+id]);
	}
	
	edit(id){
		this.router.navigate(['/'+this.object + '/'+this.service.merchantId +'/edit/'+id]);
	}

	onFilterTextBoxChanged(value): void {
		this.gridApi.setQuickFilter(value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.refreshHeader();
		if(this.visible)
		this.sizeColumns();
	}

	sizeColumns(){
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}
}
