import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('user');
  }

  admins(): Observable<any> {
    return this.http.get(this.apiUrl + 'admin');
  }

  adminSave(data): Observable<any> {
    return this.http.post(this.apiUrl + 'admin', data);
  }

  adminUpdate(data): Observable<any> {
    return this.http.put(this.apiUrl + 'admin/' + data.id , data);
  }
}
