import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  protected apiUrl: string;

  constructor(protected http: HttpClient) {
    this.apiUrl = environment.apiUrl + 'catalog/';
  }

  states(): Observable<any> {
    return this.http.get(this.apiUrl + 'cat_state');
  }

  genders(): Observable<any> {
    return this.http.get(this.apiUrl + 'cat_gender');
  }

  typePlay(): Observable<any> {
    return this.http.get(this.apiUrl + 'cat_type_play');
  }

  orderParameter(): Observable<any> {
    return this.http.get(this.apiUrl + 'cat_order_parameter');
  }

  scholarship(): Observable<any> {
    return this.http.get(this.apiUrl + 'cat_scholarship');
  }

  scholarshipGrade(id): Observable<any> {
    return this.http.get(this.apiUrl + 'cat_scholarship_grade/' + id);
  }




}
