import { Component, OnInit } from '@angular/core';
import { faUserCircle,
          faUsers,
          faHome,
          faClipboard,
          faClipboardList,
          faTextHeight,
          faGraduationCap,
          faEnvelopeOpenText,
          faTag,
          faIndustry,
          faUser,
          faFileExcel
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  faUserCircle = faUserCircle;
  faUsers = faUsers;
  faUser = faUser;
  faClipboard = faClipboard;
  faClipboardList = faClipboardList;
  faHome = faHome;
  faTextHeight = faTextHeight;
  faGraduationCap = faGraduationCap;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faTag = faTag;
  faIndustry = faIndustry;
  faFileExcel = faFileExcel;

  constructor() { }

  ngOnInit() {
  }

}
