import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FormComponent } from 'src/app/shared/form/form.component';
import { CategoryService } from '../category.service';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.css']
})
export class CategoryFormComponent extends FormComponent implements OnInit {
  faClipboard = faClipboard;
  image;
  urlImage: any;
  loading = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  fileToUpload: File;
  imgResultBeforeCompress:string;
  imgResultAfterCompress:string;

  constructor(
    public service: CategoryService,
    public imageCompress: NgxImageCompressService) {
      super(service);
    }

    ngOnInit() {
      super.ngOnInit();
      this.redirect = ['/category'];

      this.form = this.formBuilder.group({
        name: ['', [Validators.required,Validators.maxLength(255)]],
        background_rgb: ['', [Validators.required,Validators.maxLength(20)]],
      });  
    }
  
    dataToForm(data) {
      if(data.icon_url != null){
        this.urlImage = data.cloudinary_url + data.icon_url;
      }
      
      return {
        name: data.name,
        background_rgb: data.background_rgb,
      };
    }
  
    formToData() {
      const formData: FormData = new FormData();
      if(this.fileToUpload != null){
        formData.append('file', this.fileToUpload);
      }
      
      formData.append('name', this.f.name.value);
      formData.append('background_rgb', this.f.background_rgb.value);

      this.data = formData;
    }

    fileChangeEvent(event: any): void {
      const FileSize = event.target.files.item(0).size / 1024 / 1024; // in MB
      if (FileSize > 10) {
        event.value = '';
        this.alertify.error('El tamaño del archivo excede los 10 MB');
        return;
      }
      this.fileToUpload = event.target.files.item(0);
    }
    
    validation(){ 
      if(this.fileToUpload == null && this.create()){
        this.alertify.error(this.translate.instant('general.form.upload_image_required'));
        return false;
      }

      return true;
    }
  
    checkUpload() {
      if (this.showCropper) {
        this.alertify.error('Presione el boton listo, para recortar la imagen');
        return;
      }
  
      this.submitted = true;
      if (this.form.invalid || !this.validation()) {
        this.alertify.error(this.translate.instant('general.form.complete_fields'));
        return;
      }

      if(this.fileToUpload == null && this.create()){
        this.alertify.error(this.translate.instant('general.form.upload_image_required'));
        return;
      }
  
      this.onSubmit();
  
    }

}
