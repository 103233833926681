<nav aria-label="breadcrumb">
  <ol class="breadcrumb nav-breadcrumb pl-5">
    <li><img src="assets/images/usuarios.png" class="breadcrum-image"></li>
    <li class="breadcrumb-item"><a routerLink="/home" class="text-primary-color-3">{{ "home.home" | translate }}</a></li>
    <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{ "admin.users" | translate }}</li>
  </ol>
</nav> 
<div class="d-flex justify-content-center pt-3">
    <div class="col-md-10">
        <div>
            <form>
              <div class="form-group">
                <div class="form-inline float-left mb-4">
                  <div class="icon-addon">
                      <img src="assets/images/icon_buscar2x.png">
                      <input class="form-control ml-2 input-search" type="text" placeholder="{{ 'list.search' | translate }}" name="searchTerm" [(ngModel)]="userListService.searchTerm" />
                  </div>
                  <div>
                    <span class="ml-3 text-primary-color-3 font-weight-bold" *ngIf="userListService.loading$ | async">{{ "list.loading" | translate }}</span>
                  </div>
                </div>
                <div class="float-right mb-4">
                  <button class="btn btn-secondary" routerLink="/user/create"><fa-icon [icon]="faPlus" class="mr-2"></fa-icon> {{ "user.create" | translate }}</button>
                </div>
              </div>
            </form>
        </div>
        <table class="table table-striped">
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ "user.form.name" | translate }}</th>
            <th scope="col">{{ "user.form.email" | translate }}</th>
            <th class="actions" scope="col">{{ "list.actions" | translate }}</th>
          </tr>
          <tr *ngFor="let user of data$ | async">
            <td scope="row"><ngb-highlight [result]="user.id" [term]="userListService.searchTerm"></ngb-highlight></td>
            <td><ngb-highlight [result]="user.name" [term]="userListService.searchTerm"></ngb-highlight></td>
            <td><a href="mailto:{{user.email}}"><ngb-highlight [result]="user.email" [term]="userListService.searchTerm"></ngb-highlight></a></td>
            <td>
              <button class="btn btn-default" (click)="open(content,user.id)">
                <fa-icon [icon]="faKey"></fa-icon>
              </button>
              <button class="btn btn-default text-primary-color-2" (click)="show(user.id)">
                <img src="assets/images/ver_active2x.png" class="icon-list">
              </button>
              <button class="btn btn-default" (click)="edit(user.id)">
                <img src="assets/images/icon_editar2x.png" class="icon-list">
              </button>
              <button class="btn btn-default" (click)="delete(user.id)">
                <img src="assets/images/icon_borrar2x.png" class="icon-list">
              </button>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-between p-2">
          <ngb-pagination [collectionSize]="total$ | async" [(page)]="userListService.page" [pageSize]="userListService.pageSize">
          </ngb-pagination>
        
          <select class="custom-select" style="width: auto" [(ngModel)]="userListService.pageSize">
            <option [ngValue]="20">20</option>
            <option [ngValue]="50">50</option>
            <option [ngValue]="100">100</option>
          </select>
        </div>
    </div>   
  </div>
  
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ "user.change_password.title" | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="user.password">{{ "user.change_password.password" | translate }}</label>
        <input class="form-control" name="password" type="password" [(ngModel)]="password"  [ngClass]="{ 'is-invalid': error }">
        <div *ngIf="error" class="invalid-feedback">
          <div> {{ "user.change_password.passwordRequired" | translate }}</div>
        </div>
      </div>
      <div class="form-group">
        <label for="repeat-password">{{ "user.change_password.confirm_password" | translate }}</label>
        <input class="form-control" name="repeat-password" type="password" [(ngModel)]="confirmPassword"  [ngClass]="{ 'is-invalid': error }">
        <div *ngIf="error" class="invalid-feedback">
          <div>{{ "user.change_password.confirmationPasswordFailed" | translate }}</div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ "form.cancel" | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="changePassword()">{{ "form.save" | translate }}</button>
    </div>
  </ng-template>
  