<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faHome" class="mr-2 h2"></fa-icon></li>
      <li *ngIf="role == 'Administrator'" class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li *ngIf="role == 'Administrator'" class="breadcrumb-item"><a routerLink="/merchant" class="text-primary-color-3">{{ "admin.merchants" | translate }}</a></li>
      <li class="breadcrumb-item"><a [routerLink]="redirect" class="text-primary-color-3">{{ "admin.setup" | translate }}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ "setup.locations" | translate }}</li>
    </ol>
  </nav> 
  <div class="d-flex justify-content-center pt-3">
      <div class="col-md-10">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
          <div class="d-flex justify-content-center">
            <div class="col-md-6 text-center">
              <h5>{{ "setup.locations" | translate }}</h5>
            </div>
          </div>
          <div class="form-group">
            <label for="name">{{ "location.form.name" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{ "location.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="address">{{ "location.form.address" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="address" type="text" [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                <div *ngIf="f.address.errors.required">{{ "location.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="city">{{ "location.form.city" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="city" type="text" [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                <div *ngIf="f.city.errors.required">{{ "location.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="state">{{ "location.form.state" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="state" type="text" [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                <div *ngIf="f.state.errors.required">{{ "location.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="country">{{ "location.form.country" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="country" type="text" [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
            <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                <div *ngIf="f.country.errors.required">{{ "location.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="zip">{{ "location.form.zip" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="zip" type="number" [ngClass]="{ 'is-invalid': submitted && f.zip.errors }">
            <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                <div *ngIf="f.zip.errors.required">{{ "location.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="phone">{{ "location.form.phone" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="phone" type="number"  pattern="[0-9]{8,13}" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                <div *ngIf="f.phone.errors.required">{{ "location.errors.required" | translate }}</div>
                <div *ngIf="f.phone.errors.pattern">{{ "location.errors.phone_pattern" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="location.email">{{ "location.form.email" | translate }}:</label>
            <input class="form-control" [readonly]="view()" name="email" type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{ "location.errors.required" | translate }}</div>
                <div *ngIf="f.email.errors.email">{{ "location.errors.email_valid" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="location_number">{{ "location.form.location_number" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="location_number" type="number" [ngClass]="{ 'is-invalid': submitted && f.location_number.errors }">
            <div *ngIf="submitted && f.location_number.errors" class="invalid-feedback">
                <div *ngIf="f.location_number.errors.required">{{ "location.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group" *ngIf="!view()">
            <label>{{ "location.form.location" | translate }}/{{ "location.form.address" | translate }}:</label>
            <input name="search" #search type="search" autocomplete="nope" class="form-control" (keydown.enter)="$event.preventDefault()"  placeholder="  " autocorrect="off" autocapitalize="off" spellcheck="off">
          </div>

          <div class="form-group">
            <label for="location">{{ "location.form.location" | translate }}:</label>
            <agm-map [latitude]="latStart" [longitude]="lngStart" (mapReady)="mapReadyHandler($event)" [zoom]="16">
              <agm-marker [latitude]="lat" [longitude]="lng">
              </agm-marker>
            </agm-map>
            <div class="mt-2">
              <div>
                <label>Lat:</label> {{ lat }}
              </div>
              <div>
                <label>Long:</label> {{ lng }}
              </div>
            </div>
          </div>
          
          <div class="form-group">
            <label for="sales_agents_id">{{"location.form.sales_agent" | translate}}:</label>
            <select [attr.disabled]="view() ? '' : null" class="form-control" name="sales_agents_id" formControlName="sales_agents_id" [ngClass]="{ 'is-invalid': submitted && f.sales_agents_id?.errors }">
              <option></option>
                <option *ngFor="let sales_agent of sales_agents"  [ngValue]="sales_agent.id">{{sales_agent.name}}</option>
            </select>
            <div *ngIf="submitted && f.sales_agents_id?.errors" class="invalid-feedback">
                <div *ngIf="f.sales_agents_id?.errors.required">{{ "location.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="status"> {{ "location.form.status" | translate }}:</label><br>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status" type="radio" name="status" id="status1" value="1">
                <label class="form-check-label" for="status1">{{ "location.active" | translate }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status" type="radio" name="status" id="status2" value="0">
                <label class="form-check-label" for="status2">{{ "location.inactive" | translate }}</label>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-3" [routerLink]="redirect">{{ "form.cancel" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="edit()" >{{ "form.update" | translate }}</button>
          </div>
        </form>
      </div>
  </div>
