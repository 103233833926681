<div class="d-flex justify-content-center">
  <div>
    <div class="d-flex justify-content-center align-items-start">
      <div class="form-inline">
        <img src="assets/images/icon_profile.png" class="icon-profile">
        <h2 class="text-primary-color-3">Perfil de Usuario</h2>
      </div>
    </div>
    <div class="container-profile shadow form-inline">
        <div>
          <div>
            <div class="font-weight-bold">
              Nombre Usuario:
            </div>
            <div class="text-primary-color-3">
                <img src="assets/images/icon_user_azul2x.png" class="icon-profile-text"> {{ auth.permissionsValue.name }}
            </div>
          </div>
          <div>
            <div>
              <div class="font-weight-bold">
                Email:
              </div>
              <div class="text-primary-color-3">
                  <img src="assets/images/icon_mail2x.png" class="icon-profile-text"> {{ auth.permissionsValue.email }}
              </div>
            </div>
            <div>
              <div class="font-weight-bold">
                  Teléfono:
              </div>
              <div class="text-primary-color-3">
                  {{ auth.permissionsValue.phone }}
              </div>
            </div>
            <div>
                <div class="font-weight-bold">
                    Ext:
                </div>
                <div class="text-primary-color-3">
                    {{ auth.permissionsValue.ext }}
                </div>
            </div>
          </div>
          <div>        
            <button class="btn btn-primary" (click)="open(content)">Cambiar password</button>
          </div>
        </div>
      </div>
  </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Cambiar contraseña</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
          <label for="user.password">Contraseña anterior:</label>
          <input class="form-control" name="password" type="password" [(ngModel)]="oldPassword"  [ngClass]="{ 'is-invalid': oldPasswordError }">
          <div *ngIf="oldPasswordError" class="invalid-feedback">
              <div> El campo contraseña es requerido</div>
          </div>
        </div>
      <div class="form-group">
        <label for="user.password">Nueva contraseña:</label>
        <input class="form-control" name="password" type="password" [(ngModel)]="newPassword"  [ngClass]="{ 'is-invalid': newPasswordError }">
        <div *ngIf="newPasswordError" class="invalid-feedback">
            <div> El campo contraseña es requerido y debe coincidir con el de confirmación.</div>
        </div>
      </div>
      <div class="form-group">
        <label for="repeat-password">Confirmar contraseña:</label>
        <input class="form-control" name="repeat-password" type="password" [(ngModel)]="confirmPassword"  [ngClass]="{ 'is-invalid': newPasswordError }">
        <div *ngIf="newPasswordError" class="invalid-feedback">
            <div>El campo confirmar contraseña es requerido y debe coincidir con el de confirmación.</div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="changePassword()">Guardar</button>
    </div>
  </ng-template>
