import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private permissionsSubject: BehaviorSubject<any>;
  public permissions: Observable<any>;
  private baseUrl: string;

  constructor(private http: HttpClient, public permissionsService: NgxPermissionsService) {
    this.baseUrl = environment.baseUrl;
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    let permissionJson = JSON.parse(localStorage.getItem('permissions'));
    this.permissionsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('permissions')));
    this.permissions = this.permissionsSubject.asObservable();
    this.permissionsService.loadPermissions(permissionJson ? permissionJson.permission: []);
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get permissionsValue(): any {
    return this.permissionsSubject.value;
  }

  public setPermissions(permissions) {
    localStorage.setItem('permissions', JSON.stringify(permissions));
    this.permissionsSubject.next(permissions);
    this.permissionsService.loadPermissions(permissions.permission);
  }

  login(user, password): Observable<any> {

    let body = {
      email: user,
      password: password
    };


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    return this.http.post<any>(this.baseUrl + 'api/login', body, httpOptions).pipe(
      map( (user) => {
        // login successful if there's a jwt token in the response
        if (user && user.access_token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      })
    );
  }

  getPermissions(): Observable<any> {
    return this.http.get(this.baseUrl + 'api/user/permissions');
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('permissions');
    this.permissionsService.flushPermissions();
    this.currentUserSubject.next(null);
    this.permissionsSubject.next(null);
  }

  changePasswordByAdmin(id, newPassword) {
    return this.http.put(this.baseUrl + 'api/user/change-password-by-admin/' + id , { password: newPassword });
  }

  changePasswordByUser(oldPassword, newPassword) {
    return this.http.put(this.baseUrl + 'api/user/change-password', { oldPassword: oldPassword, newPassword: newPassword });
  }

  checkEmail(data) {
    return this.http.put(this.baseUrl + 'api/user/check-email', data);
  }
}
