import { Component, OnInit, Injector } from '@angular/core';
import { ListService } from '../list-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertifyService } from '../alertify.service';
import { CrudService } from '../crud.service';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { faEye, faTrash, faPencilAlt, faKey, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from 'src/app/app-injector.service';
import { ICellRendererParams } from 'ag-grid-community';



@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [ListService, DecimalPipe]
})
export class ListComponent implements OnInit {
  data$: Observable<any[]>;
  total$: Observable<number>;
  data: any[];
  object: string;
  confirmationDeleteMessage: string;
  confirmDeleteMessage: string;
  faEye = faEye;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  faKey = faKey;
  faPlus = faPlus;

  protected translate: TranslateService;
  public alertify: AlertifyService;
  public route: ActivatedRoute;
  public router: Router;


  constructor(
    public service: CrudService,
    public listService: ListService
    ) {
      const injector = AppInjector.getInjector();
      this.translate = injector.get(TranslateService);
      this.route = injector.get(ActivatedRoute);
      this.alertify = injector.get(AlertifyService);
      this.router = injector.get(Router);


      this.data$ = listService.data$;
      this.total$ = listService.total$;
      this.confirmDeleteMessage = this.translate.instant('general.list.confirmation_message');
      this.confirmationDeleteMessage = this.translate.instant('general.list.confirm_question_delete');

    }

  ngOnInit() {
    this.loadData();
  }


  show(id){
    this.router.navigate(['/'+this.object+'/view/'+id]);
  }

  edit(id){
    this.router.navigate(['/'+this.object+'/edit/'+id]);
  }

  delete(id){
    this.alertify.confirm(this.translate.instant('general.list.delete'), this.confirmationDeleteMessage,() => this.deleteItem(id));
  }

  deleteItem(id){
    this.service.delete(id).subscribe((user) => {
      this.alertify.success(this.confirmDeleteMessage);
      this.loadData();
    },(error) => {
      this.alertify.error(this.translate.instant('general.error'));
    });
  }

  loadData(){
    this.service.all().subscribe((data) => {
      this.data = data;
      this.listService.setData(data);
    }, (error) => {
      this.alertify.error(this.translate.instant('general.list.load_error'));
    });
  }

  public setConfirmDeleteMessage(message:string){
    this.confirmDeleteMessage = message;
  }

  public setConfirmationDeleteMessage(message:string){
    this.confirmationDeleteMessage = message;
  }

  public setObject(object:string){
    this.object = object;
  }

  public localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

}
