<div class="height-100">
  <nav class="navbar navbar-light bg-white justify-content-between border-line-bottom nav-shadow" *ngIf="this.auth.currentUserValue">
      <div class="m-3">
        <a *ngIf="auth.currentUserValue.user.role == 'Administrator'" routerLink="/admin"><img src="assets/images/logo.png" style="width: 150px"></a>
        <a *ngIf="auth.currentUserValue.user.role == 'Merchant'" routerLink="/setup/loc"><img src="assets/images/logo.png" style="width: 150px"></a>
      </div>
      <div class="primary-color-text">
        <span [ngClass]="{'bold': lang == 'es'}" (click)="changeLanguage('es')">{{ "lang.spanish" | translate }}</span>  
        |
        <span [ngClass]="{'bold': lang == 'en'}"(click)="changeLanguage('en')">{{ "lang.english" | translate }}</span>  
      </div>
      <div class="form-inline" *ngIf="this.auth.currentUserValue">
        <button class="btn btn-default btn-lg" routerLink="/profile">
          <fa-icon [icon]="faUserCircle" class="h2"></fa-icon>
        </button>
        <button class="btn" (click)="logout()"> <strong class="pr-2 text-primary-color-3">{{ "home.exit" | translate }}</strong> </button>    
      </div>
      
    </nav>
    
    <router-outlet></router-outlet>
</div>

<footer id="sticky-footer" class="text-white" *ngIf="this.auth.currentUserValue">

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">
    <a href="http://strappcorp.com/" class="footer-link"> StrappCorp</a>, we sweat the code.
  </div>
  <!-- Copyright -->

</footer>

