import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends CrudService {

  merchantId;

  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('location');
  }

  all(): Observable<any> {
    return this.http.get(this.apiUrl + this.object+'/list/' + this.merchantId);
  }

  getdd(id): Observable<any> {
    return this.http.get(this.apiUrl + this.object+'/getdd/' + id);
  }

  agents(): Observable<any> {
    return this.http.get(this.apiUrl + 'sales-agent');
  }
}