<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faClipboard" class="mr-2 h2"></fa-icon></li>
      <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li class="breadcrumb-item" aria-current="page"><a routerLink="/category" class="text-primary-color-3">{{ "admin.categories" | translate }}</a></li>
    </ol>
  </nav> 
  <div class="d-flex justify-content-center pt-3">
      <div class="col-md-10">
        <ngx-loading [show]="loading" ></ngx-loading>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
          <div class="d-flex justify-content-center">
            <div class="col-md-6 text-center">
              <h5>{{ "admin.categories" | translate }}</h5>
            </div>
          </div>
          <div class="form-group">
            <label for="name">{{ "category.name" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{ "general.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="background_rgb">{{ "category.rgb" | translate }}:</label>
            <input class="form-control" [readonly]="view()" name="background_rgb" type="background_rgb" formControlName="background_rgb" [ngClass]="{ 'is-invalid': submitted && f.background_rgb.errors }">
            <div *ngIf="submitted && f.background_rgb.errors" class="invalid-feedback">
                <div *ngIf="f.background_rgb.errors.required">{{ "general.required" | translate }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <div class="form-group mt-2" >
                <label for="zone">{{ "reward.form.image" | translate }}:</label>
                <br>
                <input type="file" (change)="fileChangeEvent($event)" *ngIf="!view()"/>
              </div>
            </div>
            <div class="col-auto" *ngIf="urlImage">
              <img [src]="urlImage" width="200px" />
              <br>
              <div class="row align-items-center mt-2">
                <div class="col-auto" *ngIf="!view()">
                  <button type="button" class="btn btn-default p-0" (click)="urlImage=''">
                    <fa-icon class="h5" [icon]="faTrash"></fa-icon>
                  </button>
                </div>
                <div class="col-auto">
                  <a class="text-dark h5" [href]="urlImage" target="_blank"><fa-icon [icon]="faCloudDownload"></fa-icon></a>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-3" routerLink="/category">{{ "form.cancel" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="edit()" >{{ "form.update" | translate }}</button>
          </div>
        </form>
      </div>
  </div>


