<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faHome" class="mr-2 h2"></fa-icon></li>
      <li *ngIf="role == 'Administrator'" class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li *ngIf="role == 'Administrator'" class="breadcrumb-item"><a routerLink="/merchant" class="text-primary-color-3">{{ "admin.merchants" | translate }}</a></li>
      <li class="breadcrumb-item"><a [routerLink]="redirect" class="text-primary-color-3">{{ "admin.setup" | translate }}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ "setup.coupons" | translate }}</li>
    </ol>
  </nav> 
  <div class="d-flex justify-content-center pt-3">
      <div class="col-md-10">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
          <div class="d-flex justify-content-center">
            <div class="col-md-6 text-center">
              <h5>{{ "setup.coupons" | translate }}</h5>
            </div>
          </div>
          <div class="form-group">
            <label for="title">{{ "coupon.form.title" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="title" type="text" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                <div *ngIf="f.title.errors.required">{{ "coupon.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="subtitle">{{ "coupon.form.subtitle" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="subtitle" type="text" [ngClass]="{ 'is-invalid': submitted && f.subtitle.errors }">
            <div *ngIf="submitted && f.subtitle.errors" class="invalid-feedback">
                <div *ngIf="f.subtitle.errors.required">{{ "coupon.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="description">{{"coupon.form.description" | translate}}:</label>
            <textarea 
                class="form-control"
                [readonly]="view()"
                formControlName="description"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && f.description?.errors }"
                cols="30"
                rows="5">
            </textarea>
            <div *ngIf="submitted && f.description?.errors" class="invalid-feedback">
                <div *ngIf="f.description?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>

          <!-- FECHA EXP -->
          <div class="form-group">
            <label for="expiration">{{ "coupon.form.expiration" | translate  }}:</label>
            <div class="input-group">
                <input class="form-control" [readonly]="true" formControlName="expiration" 
                  [minDate]="{year: 1920, month: 1, day: 1}" ngbDatepicker #d="ngbDatepicker" type="text" 
                  [ngClass]="{ 'is-invalid': submitted && f.expiration.errors }">
                <div class="input-group-append" *ngIf="!view()">
                    <button type="button" (click)="d.toggle()"><fa-icon [icon]="faCalendarAlt" class="mr-2"></fa-icon></button>
                </div>
                <div *ngIf="submitted && f.expiration.errors" class="invalid-feedback">
                    <div *ngIf="f.expiration.errors.required">{{ "coupon.errors.required" | translate  }}</div>
                </div>
            </div>
        </div>


          <div class="form-group">
            <label for="category">{{ "coupon.form.category" | translate }}:</label>
            <input class="form-control" readonly="true" [(ngModel)]="merchant?.category.name" [ngModelOptions]="{standalone: true}" type="text">
          </div>
          <div class="form-group">
            <label for="subcategory">{{ "coupon.form.subcategory" | translate }}:</label>
            <input class="form-control" readonly="true" [(ngModel)]="merchant?.subcategory.name" [ngModelOptions]="{standalone: true}" type="text">
          </div>

          <div class="form-group">
            <label for="redemption">{{ "coupon.form.redemption" | translate }}:</label>
            <textarea 
                class="form-control"
                [readonly]="view()"
                formControlName="redemption"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && f.redemption?.errors }"
                cols="30"
                rows="5">
            </textarea>
            <div *ngIf="submitted && f.redemption.errors" class="invalid-feedback">
                <div *ngIf="f.redemption.errors.required">{{ "coupon.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="cashier">{{ "coupon.form.cashier" | translate }}:</label>
            <textarea 
                class="form-control"
                [readonly]="view()"
                formControlName="cashier"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && f.cashier?.errors }"
                cols="30"
                rows="5">
            </textarea>
            <div *ngIf="submitted && f.cashier.errors" class="invalid-feedback">
                <div *ngIf="f.cashier.errors.required">{{ "coupon.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="disclaimer">{{ "coupon.form.disclaimer" | translate }}:</label>
            <textarea 
                class="form-control"
                [readonly]="view()"
                formControlName="disclaimer"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && f.disclaimer?.errors }"
                cols="30"
                rows="5">
            </textarea>
            <div *ngIf="submitted && f.disclaimer.errors" class="invalid-feedback">
                <div *ngIf="f.disclaimer.errors.required">{{ "coupon.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="code">{{ "coupon.form.code" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="code" type="text" [ngClass]="{ 'is-invalid': submitted && f.code.errors }">
            <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                <div *ngIf="f.code.errors.required">{{ "coupon.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="all_locations"> {{ "coupon.form.all_locations" | translate }}:</label><br>
            <div class="form-check form-check-inline">
                <input class="form-check-input" #allLoc [attr.disabled]="view() ? '' : null" formControlName="all_locations" type="radio" name="all_locations" id="all_locations1" value="1">
                <label class="form-check-label" for="all_locations1">{{ "coupon.form.yes" | translate }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="all_locations" type="radio" name="all_locations" id="all_locations2" value="0">
                <label class="form-check-label" for="all_locations2">No</label>
            </div>
          </div>
          <div class="form-group" [hidden]="allLoc.checked">
            <label for="location">{{"coupon.form.location" | translate}}:</label>
              <div class="form-check" *ngFor="let location of locations">
                <input [attr.disabled]="view() ? '' : null" class="form-check-input" type="checkbox" [checked]='location.checked'
                [value]="location.id" [id]="'workCheck'+location.id" (change)="validCheck($event)">
                <label class="form-check-label" for="flexCheckDefault">{{location.name}}</label>
              </div>
          </div>

          <div class="form-group">
            <label for="status"> {{ "coupon.form.status" | translate }}:</label><br>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status" type="radio" name="status" id="status1" value="1">
                <label class="form-check-label" for="status1">{{ "coupon.active" | translate }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status" type="radio" name="status" id="status2" value="0">
                <label class="form-check-label" for="status2">{{ "coupon.inactive" | translate }}</label>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-3" [routerLink]="redirect">{{ "form.cancel" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="edit()" >{{ "form.update" | translate }}</button>
          </div>
        </form>
      </div>
  </div>
