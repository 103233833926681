import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { RoleService } from '../../role-module/role.service';
import { CatalogService } from 'src/app/shared/catalog.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { FormComponent } from 'src/app/shared/form/form.component';
import { AuthService } from 'src/app/auth-module/auth.service';


@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent extends FormComponent implements OnInit {
  roles: any;
  areas: any;
  typeTests: any;
  events: any;
  selectedRole: any;
  permissionCapture = false;
  selectedEvents = [];
  selectedTests = [];

  currentUser = this.authenticationService.currentUserValue;
  constructor(
    public userService: UserService,
    public roleService: RoleService,
    public catalogService: CatalogService,
    private authenticationService: AuthService) {
      super(userService);
    }

  ngOnInit() {
    super.ngOnInit();
    this.redirect = ['/user'];
    if (this.create()) {
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        role_id: ['', Validators.required],
      });
    } else {
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        role_id: ['', Validators.required],
      });
    }

    this.roleService.all().subscribe((response) => {
      this.roles = response;
    }, (error) => {
      this.alertify.error(this.translate.instant('role.list.error_load'));

    });
  }

  dataToForm(data) {
    return {
      name: data.name,
      email: data.email,
      role_id: data.role_id || 0
    };
  }

  formToData() {
    this.data = {
      name: this.f.name.value,
      email: this.f.email.value,
      role_id: this.f.role_id.value,
      password: this.f.password ? this.f.password.value : null,
      events: this.selectedEvents,
      type_test: this.selectedTests
    };
  }

}

