<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faClipboardList" class="mr-2 h2"></fa-icon></li>
      <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li class="breadcrumb-item" aria-current="page"><a routerLink="/category" class="text-primary-color-3">{{ "admin.subcategories" | translate }}</a></li>
    </ol>
  </nav> 
  <div class="d-flex justify-content-center pt-3">
      <div class="col-md-10">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
          <div class="d-flex justify-content-center">
            <div class="col-md-6 text-center">
              <h5>{{ "admin.subcategories" | translate }}</h5>
            </div>
          </div>
          <div class="form-group">
            <label for="name">{{ "subcategory.name" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{ "general.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="category">{{"subcategory.category" | translate}}:</label>
            <select class="form-control" name="category_id" formControlName="category_id" [ngClass]="{ 'is-invalid': submitted && f.category_id?.errors }">
              <option></option>
                <option *ngFor="let category of categories"  [ngValue]="category.id">{{category.name}}</option>
            </select>
            <div *ngIf="submitted && f.category_id?.errors" class="invalid-feedback">
                <div *ngIf="f.category_id?.errors.required">{{ "general.required" | translate }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-3" routerLink="/category">{{ "form.cancel" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="edit()" >{{ "form.update" | translate }}</button>
          </div>
        </form>
      </div>
  </div>



