import { Component, OnInit } from '@angular/core';
import { faUserCircle, faChartBar } from '@fortawesome/free-regular-svg-icons';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  faUserCircle = faUserCircle;
  faChartBar = faChartBar;
  constructor(
  ) {

  }
  ngOnInit() {
  }

}

