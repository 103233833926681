import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends CrudService {

  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('category');
  }

  subcategories(id: string): Observable<any> {
    return this.http.get(this.apiUrl + this.object + '/' + id + '/subcategories');
  }

  update(data: any): Observable<any> {
    return this.http.post(this.apiUrl + this.object + '/' + data.id, data);
  }
}
