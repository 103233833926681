<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faHome" class="mr-2 h2"></fa-icon></li>
      <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ "admin.merchants" | translate }}</li>
    </ol>
</nav> 
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-10">          
		<div class="row my-lg-4 my-md-4">
			<div class="col">
				<input class="form-control mr-sm-4" name="search" type="search" placeholder='{{ "list.search" | translate }}' aria-label="Search" (keyup)="onFilterTextBoxChanged($event)">
			</div>
			<div class="col">
				<button class="btn btn-secondary" routerLink="/merchant/create">
					<fa-icon [icon]="faPlus" class="mr-2"></fa-icon>{{ "merchant.create" | translate }}
				</button>
			</div>
		</div>
		<ag-grid-angular 
			style="width: 100%; height: 520px;"
			class="ag-theme-alpine"
			[rowData]="data"
			[pagination]="true"
			[paginationPageSize]="10"
			[gridOptions]="gridOptions"
			(gridReady)="onGridReady($event)"
			[localeText]="gridLocale"
			>
		</ag-grid-angular>
	</div>   
</div>

<ng-template #resetPassword let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{ "user.change_password.title" | translate }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label for="user.password">{{ "user.change_password.password" | translate }}</label>
			<input class="form-control" type="password" [(ngModel)]="password"  [ngClass]="{ 'is-invalid': error }">
			<div *ngIf="error" class="invalid-feedback">
				<div> {{ "user.change_password.password_required" | translate }}</div>
			</div>
		</div>
		<div class="form-group">
			<label for="repeat-password">{{ "user.change_password.confirm_password" | translate }}</label>
			<input class="form-control" type="password" [(ngModel)]="confirmPassword"  [ngClass]="{ 'is-invalid': error }">
			<div *ngIf="error" class="invalid-feedback">
				<div>{{ "user.change_password.confirmation_password_failed" | translate }}</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ "form.cancel" | translate }}</button>
		<button type="button" class="btn btn-primary" (click)="changePassword()">{{ "form.save" | translate }}</button>
	</div>
</ng-template>

<ng-template #createUser let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Create user</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label for="user.password">{{ "user.form.email" | translate }}</label>
			<input class="form-control" type="email" [(ngModel)]="email"  [ngClass]="{ 'is-invalid': repeatedEmail }">
			<div *ngIf="repeatedEmail" class="invalid-feedback">
				<div> {{ "user.errors.invalid_email" | translate }} </div>
			</div>
		</div>
		<div class="form-group">
			<label for="user.password">{{ "user.change_password.password" | translate }}</label>
			<input class="form-control"  type="password" [(ngModel)]="password"  [ngClass]="{ 'is-invalid': error }">
			<div *ngIf="error" class="invalid-feedback">
				<div> {{ "user.change_password.password_required" | translate }}</div>
			</div>
		</div>
		<div class="form-group">
			<label for="repeat-password">{{ "user.change_password.confirm_password" | translate }}</label>
			<input class="form-control" type="password" [(ngModel)]="confirmPassword"  [ngClass]="{ 'is-invalid': error }">
			<div *ngIf="error" class="invalid-feedback">
				<div>{{ "user.change_password.confirmation_password_failed" | translate }}</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ "form.cancel" | translate }}</button>
		<button type="button" class="btn btn-primary" (click)="saveUser()">{{ "form.save" | translate }}</button>
	</div>
</ng-template>

    

