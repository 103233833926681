import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';

interface SearchResult {
  data: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: any;
}

interface matches{
  matches(data: any, term: any, pipe: PipeTransform):any[];
}


@Injectable({
  providedIn: 'root'
})
export class ListService implements matches{
  
  protected _loading$ = new BehaviorSubject<boolean>(true);
  protected _search$ = new Subject<void>();
  protected _data$ = new BehaviorSubject<any[]>([]);
  protected _total$ = new BehaviorSubject<number>(0);

  public data:any[] = new Array<any>();

  protected _state: State = {
    page: 1,
    pageSize: 20,
    searchTerm: ''
  };

  constructor(protected pipe: DecimalPipe) {
  }

  get data$() { return this._data$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({page}); }
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set searchTerm(searchTerm: any) { this._set({searchTerm}); }

  protected _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  public setData(data){
    this.data = data;
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._data$.next(result.data);
      this._total$.next(result.total);
    });

    this._search$.next();
  }

  protected _search(): Observable<SearchResult> {
    const { pageSize, page, searchTerm} = this._state;
    // 1. filter
    let data = this.data.filter(user => this.matches(user, searchTerm.toLowerCase(), this.pipe));
    const total = data.length;

    // 2. paginate
    data = data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({data, total});
  }

  public matches(data: any, term: string, pipe: PipeTransform) {
    return data;
  }
}