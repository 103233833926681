import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { RoleService } from '../../role-module/role.service';
import { CatalogService } from 'src/app/shared/catalog.service';
import { FormComponent } from 'src/app/shared/form/form.component';
import { AuthService } from 'src/app/auth-module/auth.service';
import { UserService } from 'src/app/user-module/user.service';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.css']
})
export class AdminFormComponent extends FormComponent implements OnInit {
  faUsers = faUsers;
  currentUser = this.authenticationService.currentUserValue;
  constructor(
    public service: UserService,
    public roleService: RoleService,
    public catalogService: CatalogService,
    private authenticationService: AuthService) {
      super(service);
    }

  ngOnInit() {
    super.ngOnInit();
    this.redirect = ['/admin/admins'];
    if (this.create()) {
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      });
    } else {
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
      });
    }

  }

  dataToForm(data) {
    return {
      name: data.name,
      email: data.email
    };
  }

  formToData() {
    this.data = {
      name: this.f.name.value,
      email: this.f.email.value,
      password: this.f.password ? this.f.password.value : null,
    };
  }

  save() {
    this.service.adminSave(this.data).subscribe((response) => {
      this.alertify.success(this.translate.instant('general.form.success_save'));
      this.router.navigate(this.redirect);
    }, (error) => {
      this.alertify.error(this.translate.instant('general.error'));
    });
  }

  update() {
    this.data.id = this.currentID;
    this.service.adminUpdate(this.data).subscribe((response) => {
      this.alertify.success(this.translate.instant('general.form.success_update'));
      this.router.navigate(this.redirect);
    }, (error) => {
      this.alertify.error(this.translate.instant('general.error'));

    });
  }

}
