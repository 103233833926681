
<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faHome" class="mr-2 h2"></fa-icon></li>
      <li *ngIf="role == 'Administrator'" class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li *ngIf="role == 'Administrator'" class="breadcrumb-item"><a routerLink="/merchant" class="text-primary-color-3">{{ "admin.merchants" | translate }}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ "admin.setup" | translate }}</li>
    </ol>
</nav> 
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-10">          
		<div class="row my-lg-4 my-md-4">
			<div class="col-lg-6 col-xs-12">
				<input class="form-control mr-sm-4" #search type="search" placeholder='{{ "list.search" | translate }}' 
                    aria-label="Search" (keyup)="onFilterTextBoxChanged($event,locApp,couApp,rewApp,beaApp)">
			</div>
		</div>
		
        <div class="row">
            <div class="col-lg-2 col-xs-6 p-lg-0 ml-lg-3 align-self-start">
                <button type="button" [class]="getClass(isLocationCollapsed,'loc')" 
                    (click)="showLocations(locApp, search)"
                    [attr.aria-expanded]="!isLocationCollapsed"
                    aria-controls="collapseSetup">
                    <fa-icon [icon]="faMapMarkerAlt" class="mr-2"></fa-icon>{{ "setup.locations" | translate }}
                </button>
            </div>
            <div class="col-lg-2 col-xs-6 p-lg-0 align-self-start">
                <button type="button" [class]="getClass(isCouponsCollapsed,'cou')" 
                    (click)="showCoupons(couApp, search)"
                    [attr.aria-expanded]="!isCouponsCollapsed"
                    aria-controls="collapseSetup">
                    <fa-icon [icon]="faTicketAlt" rotate="90" class="mr-2"></fa-icon>{{ "setup.coupons" | translate }}
                </button>
            </div>
            <div class="col-lg-2 col-xs-6 p-lg-0 align-self-start">
                <button type="button" [class]="getClass(isRewardsCollapsed,'rew')" 
                    (click)="showRewards(rewApp,search)"
                    [attr.aria-expanded]="!isRewardsCollapsed"
                    aria-controls="collapseSetup">
                    <fa-icon [icon]="faThLarge" class="mr-2"></fa-icon>{{ "setup.rewards" | translate }}
                </button>
            </div>
            <div class="col-lg-2 col-xs-6 p-lg-0 align-self-start" *ngIf="role == 'Administrator'">
                <button type="button" [class]="getClass(isBeaconsCollapsed,'bea')" 
                    (click)="showBeacons(beaApp,search)"
                    [attr.aria-expanded]="!isBeaconsCollapsed"
                    aria-controls="collapseSetup">
                    <fa-icon [icon]="faBullseye" class="mr-2"></fa-icon>{{ "setup.beacons" | translate }}
                </button>
            </div>

            <div class="col-lg-3 col-xs-6 align-self-end">
                <div class="row justify-content-end">
                    <div class="col-auto">
                        <button class="btn btn-secondary" [routerLink]="create()">
                            {{ "setup.add" | translate }}<fa-icon [icon]="faPlus" class="ml-2"></fa-icon>
                        </button>
                    </div>
                </div>
			</div>
        </div>

        <div class="row">
            <div class="col">
                <div #locCollapse="ngbCollapse" [(ngbCollapse)]="isLocationCollapsed">
                    <div class="card">
                        <div class="card-body">
                            <app-location-list #locApp [visible]="!isLocationCollapsed">
                            </app-location-list>
                        </div>
                    </div>
                </div>
                <div #couCollapse="ngbCollapse" [(ngbCollapse)]="isCouponsCollapsed">
                    <div class="card">
                        <div class="card-body">
                            <app-coupon-list #couApp [visible]="!isCouponsCollapsed">
                            </app-coupon-list>
                        </div>
                    </div>
                </div>
                <div #rewCollapse="ngbCollapse" [(ngbCollapse)]="isRewardsCollapsed">
                    <div class="card">
                        <div class="card-body">
                            <app-reward-list #rewApp [visible]="!isRewardsCollapsed">
                            </app-reward-list>
                        </div>
                    </div>
                </div>
                <div #beaCollapse="ngbCollapse" [(ngbCollapse)]="isBeaconsCollapsed">
                    <div class="card">
                        <div class="card-body">
                            <app-beacon-list #beaApp [visible]="!isBeaconsCollapsed">
                            </app-beacon-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
	</div>   
</div>