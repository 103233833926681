import { Injectable } from '@angular/core';
import { CrudService } from '../shared/crud.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TagService extends CrudService {

  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('tag');
  }

}