<div class="d-flex justify-content-center mt-4">
    <div class="row col-md-10">
        <div routerLink="/admin" class="col-md-2 btn-home shadow">
            <div class="btn-home-icon">
                <img src="assets/images/icon_admin2x.png" class="icon-home">
            </div>
            <div class="btn-home-label bg-strapp"> {{ "home.administration" | translate}}</div>
        </div>
    </div>
</div>

