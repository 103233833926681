import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';


import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminListComponent } from './admin-list/admin-list.component';
import { AdminFormComponent } from './admin-form/admin-form.component';
import { MerchantListComponent } from './merchant-list/merchant-list.component';
import { MerchantFormComponent } from './merchant-form/merchant-form.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MerchantSetupModule } from './merchant-setup/merchant-setup.module';
import { NgxLoadingModule } from 'ngx-loading';
import { NotificationGeneralListComponent } from './notification-general-list/notification-general-list.component';
import { NotificationGeneralFormComponent } from './notification-general-form/notification-general-form.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryFormComponent } from './category-form/category-form.component';
import { SubcategoryFormComponent } from './subcategory-form/subcategory-form.component';
import { SubcategoryListComponent } from './subcategory-list/subcategory-list.component';
import { TagFormComponent } from './tag-form/tag-form.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { SalesAgentListComponent } from './sales-agent-list/sales-agent-list.component';
import { SalesAgentFormComponent } from './sales-agent-form/sales-agent-form.component';
import { SponsorFormComponent } from './sponsor-form/sponsor-form.component';
import { SponsorListComponent } from './sponsor-list/sponsor-list.component';
import { MerchantReportComponent } from './merchant-report/merchant-report.component';
import { CustomerReportComponent } from './customer-report/customer-report.component';

@NgModule({
  declarations: [
    AdminListComponent,
    AdminFormComponent,
    MerchantListComponent,
    MerchantFormComponent,
    NotificationGeneralListComponent,
    NotificationGeneralFormComponent,
    CategoryListComponent,
    CategoryFormComponent,
    SubcategoryFormComponent,
    SubcategoryListComponent,
    TagFormComponent,
    TagListComponent,
    SalesAgentListComponent,
    SalesAgentFormComponent,
    SponsorFormComponent,
    SponsorListComponent,
    MerchantReportComponent,
    CustomerReportComponent,
  ],
  imports: [
    AdminRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild(),
    NgbModule,
    FontAwesomeModule,
    TranslateModule,
    AgGridModule,
    SelectDropDownModule,
    ImageCropperModule,
    MerchantSetupModule,
    NgxLoadingModule
  ]
})
export class AdminModule { }