<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faHome" class="mr-2 h2"></fa-icon></li>
      <li *ngIf="role == 'Administrator'" class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li *ngIf="role == 'Administrator'" class="breadcrumb-item"><a routerLink="/merchant" class="text-primary-color-3">{{ "admin.merchants" | translate }}</a></li>
      <li class="breadcrumb-item"><a [routerLink]="redirect" class="text-primary-color-3">{{ "admin.setup" | translate }}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ "setup.rewards" | translate }}</li>
    </ol>
  </nav> 
  <div class="d-flex justify-content-center pt-3">
      <div class="col-md-10">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
            <ngx-loading [show]="loading" ></ngx-loading>
          <div class="d-flex justify-content-center">
            <div class="col-md-6 text-center">
              <h5>{{ "setup.rewards" | translate }}</h5>
            </div>
          </div>
          <div class="form-group">
            <label for="title">{{ "reward.form.title" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="title" type="text" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                <div *ngIf="f.title.errors.required">{{ "reward.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="subtitle">{{ "reward.form.subtitle" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="subtitle" type="text" [ngClass]="{ 'is-invalid': submitted && f.subtitle.errors }">
            <div *ngIf="submitted && f.subtitle.errors" class="invalid-feedback">
                <div *ngIf="f.subtitle.errors.required">{{ "reward.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="description">{{"reward.form.description" | translate}}:</label>
            <textarea 
                class="form-control"
                [readonly]="view()"
                formControlName="description"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && f.description?.errors }"
                cols="30"
                rows="10">
            </textarea>
            <div *ngIf="submitted && f.description?.errors" class="invalid-feedback">
                <div *ngIf="f.description?.errors.required">{{ "reward.errors.required" | translate }}</div>
            </div>
          </div>

          
          <div class="form-group">
            <label for="start">{{ "reward.form.start" | translate  }}:</label>
            <div class="input-group">
                <input class="form-control" [readonly]="true" formControlName="start" 
                  [minDate]="{year: 1920, month: 1, day: 1}" ngbDatepicker #d="ngbDatepicker" type="text" 
                  [ngClass]="{ 'is-invalid': submitted && f.start.errors }">
                <div class="input-group-append" *ngIf="!view()">
                    <button type="button" (click)="d.toggle()"><fa-icon [icon]="faCalendarAlt" class="mr-2"></fa-icon></button>
                </div>
                <div *ngIf="submitted && f.start.errors" class="invalid-feedback">
                    <div *ngIf="f.start.errors.required">{{ "reward.errors.required" | translate  }}</div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="stop">{{ "reward.form.stop" | translate  }}:</label>
            <div class="input-group">
                <input class="form-control" [readonly]="true" formControlName="stop" 
                  [minDate]="{year: 1920, month: 1, day: 1}" ngbDatepicker #dd="ngbDatepicker" type="text" 
                  [ngClass]="{ 'is-invalid': submitted && f.stop.errors }">
                <div class="input-group-append" *ngIf="!view()">
                    <button type="button" (click)="dd.toggle()"><fa-icon [icon]="faCalendarAlt" class="mr-2"></fa-icon></button>
                </div>
                <div *ngIf="submitted && f.stop.errors" class="invalid-feedback">
                    <div *ngIf="f.stop.errors.required">{{ "reward.errors.required" | translate  }}</div>
                </div>
            </div>
        </div>


          <div class="form-group">
            <label for="category">{{ "reward.form.category" | translate }}:</label>
            <input class="form-control" readonly="true" [(ngModel)]="merchant?.category.name" [ngModelOptions]="{standalone: true}" type="text">
          </div>
          <div class="form-group">
            <label for="subcategory">{{ "reward.form.subcategory" | translate }}:</label>
            <input class="form-control" readonly="true" [(ngModel)]="merchant?.subcategory.name" [ngModelOptions]="{standalone: true}" type="text">
          </div>

          <div class="form-group">
            <label for="punches">{{ "reward.form.punches" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="punches" type="number" [ngClass]="{ 'is-invalid': submitted && f.punches.errors }">
            <div *ngIf="submitted && f.punches.errors" class="invalid-feedback">
                <div *ngIf="f.punches.errors.required">{{ "reward.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="all_locations"> {{ "coupon.form.all_locations" | translate }}:</label><br>
            <div class="form-check form-check-inline">
                <input class="form-check-input" #allLoc [attr.disabled]="view() ? '' : null" formControlName="all_locations" type="radio" name="all_locations" id="all_locations1" value="1">
                <label class="form-check-label" for="all_locations1">{{ "coupon.form.yes" | translate }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="all_locations" type="radio" name="all_locations" id="all_locations2" value="0">
                <label class="form-check-label" for="all_locations2">No</label>
            </div>
          </div>
          <div class="form-group" [hidden]="allLoc.checked">
            <label for="location">{{"coupon.form.location" | translate}}:</label>
              <div class="form-check" *ngFor="let location of locations">
                <input [attr.disabled]="view() ? '' : null" class="form-check-input" type="checkbox" [checked]='location.checked'
                [value]="location.id" [id]="'workCheck'+location.id" (change)="validCheck($event)">
                <label class="form-check-label" for="flexCheckDefault">{{location.name}}</label>
              </div>
          </div>
          
          <div class="form-group">
            <label for="status"> {{ "reward.form.status" | translate }}:</label><br>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status" type="radio" name="status" id="status1" value="1">
                <label class="form-check-label" for="status1">{{ "reward.active" | translate }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status" type="radio" name="status" id="status2" value="0">
                <label class="form-check-label" for="status2">{{ "reward.inactive" | translate }}</label>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-3" [routerLink]="redirect">{{ "form.cancel" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="edit()" >{{ "form.update" | translate }}</button>
          </div>
        </form>
      </div>
  </div>
