import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FormComponent } from 'src/app/shared/form/form.component';
import { TagService } from '../tag.service';

@Component({
  selector: 'app-tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.css']
})
export class TagFormComponent extends FormComponent implements OnInit {
  faTag = faTag;
  constructor(
    public service: TagService) {
      super(service);
    }

    ngOnInit() {
      super.ngOnInit();
      this.redirect = ['/tag'];

      this.form = this.formBuilder.group({
        name: ['', [Validators.required,Validators.maxLength(255)]]
      });  
    }

    dataToForm(data) {
      return {
        name: data.name
      };
    }
  
    formToData() {
      this.data = {
        name: this.f.name.value
      };
    }

}
