import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from '@angular/common/http';
import { JwtInterceptor } from './auth-module/jwt.interceptor';
import { ErrorInterceptor } from './auth-module/error.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgxImageCompressService} from 'ngx-image-compress';

import { AuthModule } from './auth-module/auth.module';
import { AlertifyService } from './shared/alertify.service';
import { ListComponent } from './shared/list/list.component';
import { FormComponent } from './shared/form/form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UserModule } from './user-module/user.module';
import { RoleModule } from './role-module/role.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { AdminComponent } from './admin/admin.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInjector } from './app-injector.service';
import { AdminModule } from './admin/admin.module';
import { AgGridModule } from 'ag-grid-angular';
import { ActionsCellRenderer } from './shared/list/renderer/actions-cell.renderer';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    FormComponent,
    AdminComponent,
    ActionsCellRenderer
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    FontAwesomeModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    UserModule,
    AdminModule,
    RoleModule,
    NgbModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.chasingDots,
      backdropBackgroundColour: 'rgba(180,180,180,.3)',
      backdropBorderRadius: '4px',
      primaryColour: '#a42244',
      secondaryColour: '#a42244',
      tertiaryColour: '#a42244'}),
    FormsModule,
    ImageCropperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    }),
    AgGridModule.withComponents([])
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AlertifyService,
    NgxImageCompressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
  AppInjector.setInjector(injector);
}}

