import { Component, OnInit, ViewChild } from '@angular/core';
import { faBullseye, faHome, faMapMarked, faMapMarker, faMapMarkerAlt, faPlus, faThLarge, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from '../../../app-injector.service';
import { AuthService } from 'src/app/auth-module/auth.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {

  merchantId;
  role;

  faPlus = faPlus;
  faHome = faHome;
  faMapMarkerAlt = faMapMarkerAlt;
  faTicketAlt = faTicketAlt;
  faThLarge = faThLarge;
  faBullseye= faBullseye;
  isLocationCollapsed = false;
  isCouponsCollapsed = false;
  isRewardsCollapsed = false;
  isBeaconsCollapsed = false;

  public route: ActivatedRoute;
  protected translate: TranslateService;
  protected injector;

  constructor(
    public auth: AuthService
    ) {
      const injector = AppInjector.getInjector();
      this.translate = injector.get(TranslateService);
      this.route = injector.get(ActivatedRoute);

      this.role = auth.currentUserValue.user.role;

      if(this.role == 'Merchant'){
        this.merchantId = auth.currentUserValue.user.merchant.id;
      }else{
        this.merchantId = this.route.firstChild.snapshot.paramMap.get('idMerchant');
      }
      
      
      let opt = this.route.firstChild.snapshot.paramMap.get('opt');

      if(opt == null){
        opt = 'loc';
      }
      this.isLocationCollapsed = opt !== 'loc';
      this.isCouponsCollapsed = opt !== 'cou';
      this.isRewardsCollapsed = opt !== 'rew';
      this.isBeaconsCollapsed = opt !== 'bea';
   }

  ngOnInit(): void {
  }

  onFilterTextBoxChanged($event, locApp, couApp, rewApp, beaApp): void {
    if(!this.isLocationCollapsed)
      locApp.onFilterTextBoxChanged($event.target.value);
    if(!this.isCouponsCollapsed)
      couApp.onFilterTextBoxChanged($event.target.value);
    if(!this.isRewardsCollapsed)
      rewApp.onFilterTextBoxChanged($event.target.value);
    if(!this.isBeaconsCollapsed)
      beaApp.onFilterTextBoxChanged($event.target.value);
	}

  create(){
    let resp = !this.isLocationCollapsed ? '/location/' :
                !this.isCouponsCollapsed ? '/coupon/' :
                !this.isRewardsCollapsed ? '/reward/' :
                !this.isBeaconsCollapsed ? '/beacon/' : '';
    return resp + this.merchantId +'/create'
  }
  showLocations(locApp, search){
    search.value = '';
    locApp.onFilterTextBoxChanged('');
    locApp.sizeColumns();
    this.isLocationCollapsed = false;
    this.isCouponsCollapsed = true;
    this.isRewardsCollapsed = true;
    this.isBeaconsCollapsed = true;
  }

  showCoupons(couApp, search){
    search.value = '';
    couApp.onFilterTextBoxChanged('');
    couApp.sizeColumns();
    this.isLocationCollapsed = true;
    this.isCouponsCollapsed = false;
    this.isRewardsCollapsed = true;
    this.isBeaconsCollapsed = true;
  }

  showRewards(rewApp,search){
    search.value = '';
    rewApp.onFilterTextBoxChanged('');
    rewApp.sizeColumns();
    this.isLocationCollapsed = true;
    this.isCouponsCollapsed = true;
    this.isRewardsCollapsed = false;
    this.isBeaconsCollapsed = true;
  }

  showBeacons(beaApp,search){
    search.value = '';
    beaApp.onFilterTextBoxChanged('');
    beaApp.sizeColumns();
    this.isLocationCollapsed = true;
    this.isCouponsCollapsed = true;
    this.isRewardsCollapsed = true;
    this.isBeaconsCollapsed = false;
  }

  getClass(active, op){
    let color;
    switch (op) {
      case 'loc':
            color='btn-outline-danger';
          break;
      case 'cou':
            color='btn-outline-warning';
          break;
      case 'rew':
            color='btn-outline-info';
          break;
      case 'bea':
            color='btn-outline-success';
          break;
    }
    return 'btn btn-block '+ (active ? 'btn-outline-secondary' : color+' font-weight-bold');  
  }
}
