import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  protected apiUrl: string;
  protected object: string;

  constructor(protected http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  protected setObject(object: string) {
    this.object = object;
  }

  all(): Observable<any> {
    return this.http.get(this.apiUrl + this.object);
  }

  get(id: string): Observable<any> {
    return this.http.get(this.apiUrl + this.object + '/' + id);
  }

  save(data: any): Observable<any> {
    return this.http.post(this.apiUrl + this.object, data);
  }

  update(data: any): Observable<any> {
    return this.http.put(this.apiUrl + this.object + '/' + data.id, data);
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + this.object + '/' + id);
  }
}
