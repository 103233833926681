import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth-module/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AlertifyService } from './shared/alertify.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  faUserCircle = faUserCircle;
  title = 'Strappcorp!';
  name: string;
  lang = 'en';
  appHome = ['/home'];
  constructor(
    public auth: AuthService,
    private permissions: NgxPermissionsService,
    private alertify: AlertifyService,
    private router: Router,
    private route: ActivatedRoute,
    public modalService: NgbModal,
    public translate: TranslateService
    ) {
  }

  ngOnInit() {
    
    this.auth.permissions.subscribe((permissions) => {
      this.name = permissions ? permissions.name : '';
    });
    this.translate.setDefaultLang(this.lang);

    if(this.auth.currentUserValue && this.auth.currentUserValue.user.role == 'Merchant'){
      this.appHome = ['/setup/loc'];
		}else{
      this.appHome = ['/home'];
		}
  }

  changeLanguage(lang) {
    this.lang = lang;
    this.translate.setDefaultLang(lang);
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/login']);
  }
}
