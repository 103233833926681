// Angular
import { Component, ComponentFactoryResolver } from '@angular/core';
// AG-Grid
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-actions-cell-renderer',
	templateUrl: './actions-cell.renderer.html',
	styleUrls: ['./actions-cell.renderer.css']
})
export class ActionsCellRenderer implements ICellRendererAngularComp {
	public actions: any;
	public id;

	/**
	 * When the component renders, it got the possible value
	 * @param params
	 */
	agInit(params: ICellRendererParams): void {
		this.id      = params['value'];
		this.actions = params['actions'];
	}

	/**
	 * Put back the value
	 * @param params
	 */
	refresh(params: ICellRendererParams): boolean {
		return true;
	}

	onClick(params) {
		params.action(this.id);
	}
}
