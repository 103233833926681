import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormComponent } from 'src/app/shared/form/form.component';
import { MerchantService } from '../merchant.service';
import { CategoryService } from '../category.service';
import { TagService } from '../tag.service';
import { faCloudDownloadAlt, faHome, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-merchant-form',
  templateUrl: './merchant-form.component.html',
  styleUrls: ['./merchant-form.component.css']
})
export class MerchantFormComponent extends FormComponent implements OnInit {
  faHome =  faHome;
  categories = [];
  subcategories = [];
  tags = [];
  tagsSelected = [];
  currentTag;
  faTimes = faTimes;
  faTrash = faTrash;
  faCloudDownload= faCloudDownloadAlt;
  images = [null,null,null,null];
  passwordError = false;

  indexCurrentImg;
  fileToUpload:[File,File,File,File,File] = [null,null,null,null,null];
  fileVisible = [false,false,false,false,false];

  constructor(
    public service: MerchantService,
    public categoryService: CategoryService,
    public tagService: TagService,
    public imageCompress: NgxImageCompressService
    ) {
      super(service);
    }

  ngOnInit() {
    super.ngOnInit();
    this.redirect = ['/merchant'];
    if (this.create()) {
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.email]],
        password: [''],
        confirmPassword: [''],
        description: [''],
        category: [{value: '', disabled: this.view()}, Validators.required],
        subcategory: [{value: '', disabled: this.view()}, Validators.required],
        facebook: [''],
        instagram: [''],
        merchant_url: [''],
      });
    } else {
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        description: [''],
        category: [{value: '', disabled: this.view()}, Validators.required],
        subcategory: [{value: '', disabled: this.view()}, Validators.required],
        facebook: [''],
        instagram: [''],
        merchant_url: [''],
        email: [''],
      });
    }

  }

  getExtras(){
    this.categoryService.all().subscribe( (response) => {
      this.categories = response;
      if(!this.create()) {
        this.changeCategory();
      }
    }, (error) => {
      this.alertify.error('No fue posible obtener las categorias');
    });

    this.tagService.all().subscribe( (response) => {
      this.tags = response;
    }, (error) => {
      this.alertify.error('No fue posible obtener los tags');
    });
  }

  changeCategory(){
    this.categoryService.subcategories(this.f.category.value).subscribe((response) => {
      this.subcategories = response;
    }, (error) => {
      this.alertify.error('No fue posible cargar las subcategorias');
    });
  }

  dataToForm(data) {
    this.tagsSelected = data.tags ? data.tags : [];
    this.images = [
        (data.logo != null) ? data.cloudinary_url + data.logo : null,
        (data.photo != null) ? data.cloudinary_url + data.photo : null,
        (data.primary_image_url != null) ? data.cloudinary_url + data.primary_image_url: null,
        (data.second_media_url != null) ? data.cloudinary_url + data.second_media_url : null,
        (data.third_media_url != null) ? data.cloudinary_url + data.third_media_url : null
    ];
    return {
      name: data.name,
      email: data.user ? data.user.email : '',
      description: data.description,
      category: data.category_id,
      subcategory: data.subcategory_id,
      facebook: data.facebook,
      instagram: data.instagram,
      merchant_url: data.merchant_url,
    };
  }

  formToData() {
    const formData = new FormData;

    let tags = this.tagsSelected.map(tag => {
                  return tag.id;
                });
          

    formData.append('name', this.f.name.value);
    if(this.f.email.value){
      formData.append('email', this.f.email.value);
    }

    if(this.f.password){
      formData.append('password', this.f.password ? this.f.password.value : null);
    }
    
    
    formData.append('description', this.f.description.value);
    formData.append('category_id', this.f.category.value);
    formData.append('subcategory_id', this.f.subcategory.value);
    formData.append('facebook', this.f.facebook.value);
    formData.append('instagram', this.f.instagram.value);
    formData.append('merchant_url', this.f.merchant_url.value);
    formData.append('tags', JSON.stringify(tags));

    formData.append('logo', this.images[0]);
    formData.append('photo', this.images[1]);
    formData.append('primary_image_url', this.images[2]);
    formData.append('second_media_url', this.images[3]);
    formData.append('third_media_url', this.images[4]);
    
    if(this.fileToUpload[0] != null){
      formData.append('logo_file', this.fileToUpload[0]);
    }

    if(this.fileToUpload[1] != null){
      formData.append('photo_file', this.fileToUpload[1]);
    }

    if(this.fileToUpload[2] != null){
      formData.append('primary_image_file', this.fileToUpload[2]);
    }

    if(this.fileToUpload[3] != null){
      formData.append('second_media_file', this.fileToUpload[3]);
    }

    if(this.fileToUpload[4] != null){
      formData.append('third_media_file', this.fileToUpload[4]);
    }

    this.data = formData;

  }

  public config = {
		displayKey: 'name',
		search: true,
		height: 'auto',
		placeholder: '-Select a tag',
		moreText: 'more',
		noResultsFound: 'Result not found!',
		searchPlaceholder: 'Search',
		searchOnKey: 'name'
	};

  tagSelect(m){
    this.currentTag = m.selectedItems.length > 0 ? m.selectedItems[0] : null;
  }


  addTag(){ 
    let has = false;
    this.tagsSelected.forEach(element => {
      if(element.id == this.currentTag.id){
        has = true;
      }
    });
    if(!has)
      this.tagsSelected.push(this.currentTag);
  }

  removeTag(value) {   
    this.tagsSelected = this.tagsSelected.filter(function(ele){ 
        return ele.id != value; 
    });
  }

  fileChangeEvent(event: any, i): void {
    const FileSize = event.target.files.item(0).size / 1024 / 1024; // in MB
    this.indexCurrentImg = i;
    this.fileVisible[i] = true;
    if (FileSize > 10) {
      event.value = '';
      this.alertify.error('File exceeds 10 MB');
      return;
    }
    
    this.fileToUpload[i] = event.target.files.item(0);
  }

  removeImg(ind){
    this.images[ind] = null;
  }

  validation(){
    if(this.create()){
      this.passwordError = false;
      if(this.f.password.value != '' || this.f.confirmPassword.value != ''){
        if(this.f.password.value == '' || this.f.confirmPassword.value == ''){
          this.passwordError = true;
          return false;
        }
      }else{
        return true;
      }
    }
    return true;
  }

  save() {
    this.loading = true;
    this.service.save(this.data).subscribe((response) => {
      this.loading = false;
      this.alertify.success(this.translate.instant('general.form.success_save'));
      this.router.navigate(this.redirect);
    }, (error) => {
      this.loading = false;
      if(error.status == 409){
        this.alertify.error(this.translate.instant('user.errors.invalid_email'));
        return;
      }
      this.alertify.error(this.translate.instant('general.error'));
    });
  }

}
