import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../auth-module/admin.guard';
import { AdminFormComponent } from './admin-form/admin-form.component';
import { AdminListComponent } from './admin-list/admin-list.component';
import { CategoryFormComponent } from './category-form/category-form.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CustomerReportComponent } from './customer-report/customer-report.component';
import { MerchantFormComponent } from './merchant-form/merchant-form.component';
import { MerchantListComponent } from './merchant-list/merchant-list.component';
import { MerchantReportComponent } from './merchant-report/merchant-report.component';
import { NotificationGeneralFormComponent } from './notification-general-form/notification-general-form.component';
import { NotificationGeneralListComponent } from './notification-general-list/notification-general-list.component';
import { SalesAgentFormComponent } from './sales-agent-form/sales-agent-form.component';
import { SalesAgentListComponent } from './sales-agent-list/sales-agent-list.component';
import { SponsorFormComponent } from './sponsor-form/sponsor-form.component';
import { SponsorListComponent } from './sponsor-list/sponsor-list.component';
import { SubcategoryFormComponent } from './subcategory-form/subcategory-form.component';
import { SubcategoryListComponent } from './subcategory-list/subcategory-list.component';
import { TagFormComponent } from './tag-form/tag-form.component';
import { TagListComponent } from './tag-list/tag-list.component';


const routes: Routes = [
  {
    path: 'admin/admins',
    component: AdminListComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/admins/create',
    component: AdminFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/admins/view/:id',
    component: AdminFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/admins/edit/:id',
    component: AdminFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'merchant',
    component: MerchantListComponent,
    canActivate: [AdminGuard],
  },{
    path: 'merchant/create',
    component: MerchantFormComponent,
    canActivate: [AdminGuard],
  },{
    path: 'merchant/report',
    component: MerchantReportComponent,
    canActivate: [AdminGuard],
  },{
    path: 'customer/report',
    component: CustomerReportComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'merchant/view/:id',
    component: MerchantFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'merchant/edit/:id',
    component: MerchantFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'notification',
    component: NotificationGeneralListComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'notification/create',
    component: NotificationGeneralFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'category',
    component: CategoryListComponent,
    canActivate: [AdminGuard],
  },{
    path: 'category/create',
    component: CategoryFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'category/view/:id',
    component: CategoryFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'category/edit/:id',
    component: CategoryFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'subcategory',
    component: SubcategoryListComponent,
    canActivate: [AdminGuard],
  },{
    path: 'subcategory/create',
    component: SubcategoryFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'subcategory/view/:id',
    component: SubcategoryFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'subcategory/edit/:id',
    component: SubcategoryFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'tag',
    component: TagListComponent,
    canActivate: [AdminGuard],
  },{
    path: 'tag/create',
    component: TagFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'tag/view/:id',
    component: TagFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'tag/edit/:id',
    component: TagFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'sales-agent',
    component: SalesAgentListComponent,
    canActivate: [AdminGuard],
  },{
    path: 'sales-agent/create',
    component: SalesAgentFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'sales-agent/view/:id',
    component: SalesAgentFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'sales-agent/edit/:id',
    component: SalesAgentFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'sponsor',
    component: SponsorListComponent,
    canActivate: [AdminGuard],
  },{
    path: 'sponsor/create',
    component: SponsorFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'sponsor/view/:id',
    component: SponsorFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'sponsor/edit/:id',
    component: SponsorFormComponent,
    canActivate: [AdminGuard],
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AdminRoutingModule { }