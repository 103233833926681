<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faHome" class="mr-2 h2"></fa-icon></li>
      <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li class="breadcrumb-item"><a routerLink="/merchant" class="text-primary-color-3">{{ "admin.merchants" | translate }}</a></li>
      <li class="breadcrumb-item"><a [routerLink]="redirect" class="text-primary-color-3">{{ "admin.setup" | translate }}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ "setup.beacons" | translate }}</li>
    </ol>
  </nav> 
  <div class="d-flex justify-content-center pt-3">
      <div class="col-md-10">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
          <div class="d-flex justify-content-center">
            <div class="col-md-6 text-center">
              <h5>{{ "setup.beacons" | translate }}</h5>
            </div>
          </div>
          <div class="form-group">
            <label for="name">{{ "beacon.form.name" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{ "beacon.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="model">{{ "beacon.form.model" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="model" type="text" [ngClass]="{ 'is-invalid': submitted && f.model.errors }">
            <div *ngIf="submitted && f.model.errors" class="invalid-feedback">
                <div *ngIf="f.model.errors.required">{{ "beacon.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="serial">{{ "beacon.form.serial" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="serial" type="text" [ngClass]="{ 'is-invalid': submitted && f.serial.errors }">
            <div *ngIf="submitted && f.serial.errors" class="invalid-feedback">
                <div *ngIf="f.serial.errors.required">{{ "beacon.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="region">{{ "beacon.form.region" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="region" type="text" [ngClass]="{ 'is-invalid': submitted && f.region.errors }">
            <div *ngIf="submitted && f.region.errors" class="invalid-feedback">
                <div *ngIf="f.region.errors.required">{{ "beacon.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="major">{{ "beacon.form.major" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="major" type="number" [ngClass]="{ 'is-invalid': submitted && f.major.errors }">
            <div *ngIf="submitted && f.major.errors" class="invalid-feedback">
                <div *ngIf="f.major.errors.required">{{ "beacon.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="minor">{{ "beacon.form.minor" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="minor" type="number" [ngClass]="{ 'is-invalid': submitted && f.minor.errors }">
            <div *ngIf="submitted && f.minor.errors" class="invalid-feedback">
                <div *ngIf="f.minor.errors.required">{{ "beacon.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="ble_mac">{{ "beacon.form.ble_mac" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="ble_mac" type="text" [ngClass]="{ 'is-invalid': submitted && f.ble_mac.errors }">
            <div *ngIf="submitted && f.ble_mac.errors" class="invalid-feedback">
                <div *ngIf="f.ble_mac.errors.required">{{ "beacon.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="location">{{"beacon.form.location" | translate}}:</label>
            <select [attr.disabled]="view() ? '' : null" class="form-control" name="location" formControlName="location" [ngClass]="{ 'is-invalid': submitted && f.location?.errors }">
              <option></option>
                <option *ngFor="let location of locations"  [ngValue]="location.id">{{location.name}}</option>
            </select>
            <div *ngIf="submitted && f.location?.errors" class="invalid-feedback">
                <div *ngIf="f.location?.errors.required">{{ "location.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="sensitivity">{{ "beacon.form.sensitivity" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="sensitivity" type="number" [ngClass]="{ 'is-invalid': submitted && f.sensitivity.errors }">
            <div *ngIf="submitted && f.sensitivity.errors" class="invalid-feedback">
                <div *ngIf="f.sensitivity.errors.required">{{ "beacon.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="status"> {{ "beacon.form.status" | translate }}:</label><br>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status" type="radio" name="status" id="status1" value="1">
                <label class="form-check-label" for="status1">{{ "beacon.active" | translate }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status" type="radio" name="status" id="status2" value="0">
                <label class="form-check-label" for="status2">{{ "beacon.inactive" | translate }}</label>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-3" [routerLink]="redirect">{{ "form.cancel" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="edit()" >{{ "form.update" | translate }}</button>
          </div>
        </form>
      </div>
  </div>
