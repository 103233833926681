import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FormComponent } from 'src/app/shared/form/form.component';
import { CategoryService } from '../category.service';
import { SubcategoryService } from '../subcategory.service';

@Component({
  selector: 'app-subcategory-form',
  templateUrl: './subcategory-form.component.html',
  styleUrls: ['./subcategory-form.component.css']
})
export class SubcategoryFormComponent extends FormComponent implements OnInit {
  faClipboardList = faClipboardList;
  categories = [];

  constructor(
    public service: SubcategoryService,
    public categoryService: CategoryService,) {
      super(service);
    }

    ngOnInit() {
      super.ngOnInit();
      this.redirect = ['/subcategory'];

      this.form = this.formBuilder.group({
        name: ['', [Validators.required,Validators.maxLength(255)]],
        category_id: ['', [Validators.required]],
      });  
    }
  
    dataToForm(data) {

      return {
        name: data.name,
        category_id: data.category_id,
      };
    }
  
    formToData() {
      this.data = {
        name: this.f.name.value,
        category_id: this.f.category_id.value,
      };
    }

    getExtras(){
      this.categoryService.all().subscribe((response) => {
        this.categories = response;
      }, (error) => {
        this.alertify.error('Error to load category catalog');
      });
    }

}
