import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends CrudService{

  constructor(protected http: HttpClient) { 
    super(http);
    this.setObject("notification");
  }

  getNotificationObject(message:string, url:string, sender:number, receiver:number, priority:string ,mail:boolean = false){
    return {
      message: message,
      url: url,
      sender_id: sender,
      receiver_id: receiver,
      priority: priority,
      mail:mail
    }
  }

  getNotifications(all:boolean){
    return this.http.get(this.apiUrl+this.object+'/show-all');
  }

  readNotification(id){
    return this.http.put(this.apiUrl+this.object+'/read/'+id,{});
  }

  readAllNotifications(){
    return this.http.put(this.apiUrl+this.object+'/read-all',{});
  }

  generalList(): any {
    return this.http.get(this.apiUrl + 'notification/general');
  }

  general(data) : any{
    return this.http.post(this.apiUrl + 'notification/general',data);
  }


}
