import { Component, NgZone, OnInit, PipeTransform, TemplateRef, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ListService } from 'src/app/shared/list-service.service';
import { ListComponent } from 'src/app/shared/list/list.component';

import { AG_GRID_LOCALE_EN } from '../../shared/list/locale/en.locale';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faDownload, faHome } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-customer-report',
  templateUrl: './customer-report.component.html',
  styleUrls: ['./customer-report.component.css'],
  providers: [ListService, DecimalPipe]
})
export class CustomerReportComponent extends ListComponent implements OnInit {

  faHome = faHome;
  faDownload = faDownload;
  faFileExcel = faFileExcel;
	
  private gridApi;
	public gridOptions: any;
	public gridLocale;


	constructor(
		public service: CustomerService,
		public listService: ListService,
		public translateService: TranslateService,
		public auth: AuthService,
		private zone: NgZone,
	) {
		super(service, listService);
		this.setObject('merchant');
	}

	ngOnInit() {
		this.gridLocale = AG_GRID_LOCALE_EN;
		this.gridOptions = {
			defaultColDef: {
				resizable: true,
			},
			columnDefs: [
				{ headerName: '#', field: 'id', sortable: true },
				{ headerName: 'customer.email', field: 'email', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'customer.created_at', field: 'created_at', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ 
          headerName: 'customer.google_login',
          field: 'google_login',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('form.yes');
						} else {
							return this.translate.instant('form.no');
						}
					}
        },
				{ 
          headerName: 'customer.facebook_login',
          field: 'facebook_login',
          sortable: true, 
          cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('form.yes');
						} else {
							return this.translate.instant('form.no');
						}
					},
          headerValueGetter: this.localizeHeader.bind(this)
        },
				{
          headerName: 'customer.email_login',
          field: 'email_login',
          sortable: true, 
          cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('form.yes');
						} else {
							return this.translate.instant('form.no');
						}
					},
          headerValueGetter: this.localizeHeader.bind(this)
        },
				{ 
          headerName: 'customer.notifications',
          field: 'notifications',
          sortable: true,
          cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('form.yes');
						} else {
							return this.translate.instant('form.no');
						}
					},
          headerValueGetter: this.localizeHeader.bind(this)
        },
				{ 
          headerName: 'customer.reminders',
          field: 'reminders',
          sortable: true,
          cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('form.yes');
						} else {
							return this.translate.instant('form.no');
						}
					},
          headerValueGetter: this.localizeHeader.bind(this)
        }
			]
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
    	this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}

  loadData(){
    this.service.report().subscribe((data) => {
      this.data = data;
      this.listService.setData(data);
    }, (error) => {
      this.alertify.error(this.translate.instant('general.list.load_error'));
    });
  }

  public getExportUrlGeneral() {
    return environment.baseUrl + 'export/customer';
  }

}
