import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocationFormComponent } from './location-form/location-form.component';
import { SetupComponent } from './setup/setup.component';
import { CouponFormComponent } from './coupon-form/coupon-form.component';
import { RewardFormComponent } from './reward-form/reward-form.component';
import { BeaconFormComponent } from './beacon-form/beacon-form.component';
import { AdminGuard } from 'src/app/auth-module/admin.guard';
import { MerchantGuard } from 'src/app/auth-module/merchant.guard';


const routes: Routes = [
  {
    path: 'setup/:idMerchant/:opt',
    component: SetupComponent,
    canActivate: [MerchantGuard],
  },{
    path: 'setup/:opt',
    component: SetupComponent,
    canActivate: [MerchantGuard],
  },{
    path: 'location/:idMerchant/create',
    component: LocationFormComponent,
    canActivate: [MerchantGuard],
  },
  {
    path: 'location/:idMerchant/view/:id',
    component: LocationFormComponent,
    canActivate: [MerchantGuard],
  },
  {
    path: 'location/:idMerchant/edit/:id',
    component: LocationFormComponent,
    canActivate: [MerchantGuard],
  },{
    path: 'coupon/:idMerchant/create',
    component: CouponFormComponent,
    canActivate: [MerchantGuard],
  },
  {
    path: 'coupon/:idMerchant/view/:id',
    component: CouponFormComponent,
    canActivate: [MerchantGuard],
  },
  {
    path: 'coupon/:idMerchant/edit/:id',
    component: CouponFormComponent,
    canActivate: [MerchantGuard],
  },
  {
    path: 'reward/:idMerchant/create',
    component: RewardFormComponent,
    canActivate: [MerchantGuard],
  },
  {
    path: 'reward/:idMerchant/view/:id',
    component: RewardFormComponent,
    canActivate: [MerchantGuard],
  },
  {
    path: 'reward/:idMerchant/edit/:id',
    component: RewardFormComponent,
    canActivate: [MerchantGuard],
  },
  {
    path: 'beacon/:idMerchant/create',
    component: BeaconFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'beacon/:idMerchant/view/:id',
    component: BeaconFormComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'beacon/:idMerchant/edit/:id',
    component: BeaconFormComponent,
    canActivate: [AdminGuard],
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SetupRoutingModule { }