import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MerchantService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('merchant');
  }

  saveUser(id: string, data: any): Observable<any> {
    return this.http.post(this.apiUrl + this.object + '/create-user/' + id , data);
  }

  upload(data: any,ind): Observable<any> {
    return this.http.post(this.apiUrl + this.object + '/uploadImage/' + ind, data);
  }

  update(data: any): Observable<any> {
    return this.http.post(this.apiUrl + this.object + '/' + data.id, data);
  }

  report(): Observable<any> {
    return this.http.get(this.apiUrl + this.object + '/report' );
  }

}
