import { Component, NgZone, OnInit, PipeTransform, TemplateRef, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ListService } from 'src/app/shared/list-service.service';
import { ListComponent } from 'src/app/shared/list/list.component';

import { AG_GRID_LOCALE_EN } from '../../shared/list/locale/en.locale';
import { AG_GRID_LOCALE_ES } from '../../shared/list/locale/es.locale';
import { ActionsCellRenderer } from 'src/app/shared/list/renderer/actions-cell.renderer';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth-module/auth.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-notification-general-list',
  templateUrl: './notification-general-list.component.html',
  styleUrls: ['./notification-general-list.component.css'],
  providers: [ListService, DecimalPipe]
})
export class NotificationGeneralListComponent extends ListComponent implements OnInit {
	faEnvelopeOpenText = faEnvelopeOpenText;
  private gridApi;
	public gridOptions: any;
	public gridLocale;

  constructor(
		public service: NotificationService,
		public listService: ListService,
		public translateService: TranslateService,
		public modalService: NgbModal,
		public auth: AuthService,
		private zone: NgZone,
	) {
		super(service, listService);
		this.setObject('notification');
	}

  ngOnInit() {
		this.gridLocale = AG_GRID_LOCALE_EN;
		this.gridOptions = {
			defaultColDef: {
				resizable: true,
			},
			columnDefs: [
				{ headerName: '#', field: 'id', sortable: true, width: 60 },
				{ headerName: 'notification.title', field: 'title', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'notification.message', field: 'message', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'notification.date', field: 'created_at', sortable: true, headerValueGetter: this.localizeHeader.bind(this) }
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

  	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
    	this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}

	loadData(){
		this.service.generalList().subscribe((data) => {
		this.data = data.data;
		this.listService.setData(data);
		}, (error) => {
		this.alertify.error(this.translate.instant('general.list.load_error'));
		});
	}

}
