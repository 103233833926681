import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationListComponent } from './location-list/location-list.component';
import { LocationFormComponent } from './location-form/location-form.component';
import { SetupRoutingModule } from './setup-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { SetupComponent } from './setup/setup.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgmCoreModule } from '@agm/core';
import { CouponListComponent } from './coupon-list/coupon-list.component';
import { CouponFormComponent } from './coupon-form/coupon-form.component';
import { RewardListComponent } from './reward-list/reward-list.component';
import { RewardFormComponent } from './reward-form/reward-form.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxLoadingModule } from 'ngx-loading';
import { BeaconFormComponent } from './beacon-form/beacon-form.component';
import { BeaconListComponent } from './beacon-list/beacon-list.component';


@NgModule({
  declarations: [
      SetupComponent,
      LocationFormComponent,
      LocationListComponent,
      CouponListComponent,
      CouponFormComponent,
      RewardListComponent,
      RewardFormComponent,
      BeaconFormComponent,
      BeaconListComponent
    ],
  imports: [
    CommonModule,
    SetupRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    TranslateModule,
    AgGridModule,
    ImageCropperModule,
    NgxLoadingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyATwUGkbtk2tnyxv6gmjil7w0_Z-RjUKy4',
      libraries: ["places"],
			language: 'es-419'
    }),
  ]
})
export class MerchantSetupModule { }
