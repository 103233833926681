import { Component, NgZone, OnInit, PipeTransform, TemplateRef, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ListService } from 'src/app/shared/list-service.service';
import { ListComponent } from 'src/app/shared/list/list.component';

import { AG_GRID_LOCALE_EN } from '../../shared/list/locale/en.locale';
import { AG_GRID_LOCALE_ES } from '../../shared/list/locale/es.locale';
import { ActionsCellRenderer } from 'src/app/shared/list/renderer/actions-cell.renderer';
import { TranslateService } from '@ngx-translate/core';
import { MerchantService } from '../merchant.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.css'],
  providers: [ListService, DecimalPipe]
})
export class MerchantListComponent extends ListComponent implements OnInit {
	faHome = faHome;
	@ViewChild('resetPassword') private resetPasswordModal: TemplateRef<any>;
	@ViewChild('createUser') private createUserModal: TemplateRef<any>;
  	private modalRef: NgbModalRef;

  	private gridApi;
	public gridOptions: any;
	public gridLocale;

	public email: string;
	public password: string;
	public confirmPassword: string;
	public error: boolean;
	public repeatedEmail: boolean;
	public currentMerchantIndex;

	constructor(
		public service: MerchantService,
		public listService: ListService,
		public translateService: TranslateService,
		public modalService: NgbModal,
		public auth: AuthService,
		private zone: NgZone,
	) {
		super(service, listService);
		this.setObject('merchant');
	}

	ngOnInit() {
		this.gridLocale = AG_GRID_LOCALE_EN;
		this.gridOptions = {
			defaultColDef: {
				resizable: true,
			},
			columnDefs: [
				{ headerName: '#', field: 'id', sortable: true, width: 60 },
				{ headerName: 'merchant.name', field: 'name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'merchant.form.category', field: 'category.name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
					headerName: 'merchant.status',
					field: 'status',
					sortable: true,
					cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('form.yes');
						} else {
							return this.translate.instant('form.no');
						}
					},
          			headerValueGetter: this.localizeHeader.bind(this)
				},
        		{
					headerName: 'merchant.user',
					field: 'user_id',
					sortable: true,
					cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('form.yes');
						} else {
							return this.translate.instant('form.no');
						}
					},
          			headerValueGetter: this.localizeHeader.bind(this)
				},
				{
					headerName: 'list.actions',
					field: 'id',
					cellRenderer: 'actionsCellRenderer',
					cellRendererParams: {
						actions: [
							{
								icon: 'icon_user_azul2x.png',
								action: (id) => {
									this.checkUser(id);
								}
							},
							{
								icon: 'ver_active2x.png',
								action: (id) => {
									this.zone.run(() => {
										this.show(id);
									});
								}
							},
							{
								icon: 'icon_editar2x.png',
								action: (id) => {
									this.zone.run(() => {
										this.edit(id);
									});
								}
							},
							{
								icon: 'icon_procesos2x.png',
								action: (id) => {
									this.zone.run(() => {
										this.setup(id);
									});
								}
							},
							{
								icon: 'icon_borrar2x.png',
								action: (id) => {
									this.delete(id);
								}
							}
						]
					},
          			headerValueGetter: this.localizeHeader.bind(this)
				}
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
    	this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}

	setup(id){
		this.router.navigate(['/setup/'+id+'/loc']);
	}

	checkUser(id){
		
		this.data.forEach((merchant, index) => {
			if(merchant.id == id){
				this.currentMerchantIndex = index;
				if(merchant.user_id == null || merchant.user_id == undefined) {
					this.openCreateUser();
				} else {
					this.openResetPassword();
				}
				
			}
		});
	}

	openResetPassword() {
		this.cleanModal();
		this.modalRef = this.modalService.open(this.resetPasswordModal);
	}

	openCreateUser() {
		this.cleanModal();
		this.modalRef = this.modalService.open(this.createUserModal);
	}

	changePassword() {
		if (!this.validResetPassword()) {
		  return;
		}
		this.auth.changePasswordByAdmin(this.data[this.currentMerchantIndex].user_id, this.password)
		.subscribe(
		  (response) => {
			this.modalService.dismissAll();
			this.alertify.success(this.translate.instant('general.success'));
		  },
		  (error) => {
			this.alertify.error(this.translate.instant('general.error'));
		  }
		);
	}

	validResetPassword() {
		if (this.password && this.confirmPassword && ( this.password === this.confirmPassword )) {
			this.error = false;
			return true;
		}
		this.error = true;
		return false;
	}

	saveUser() {
		if (!this.validCreateUser()) {
		  return;
		}

		let data = {
			email:this.email,
			password:this.password,
		};

		this.repeatedEmail = false;
		this.service.saveUser(this.data[this.currentMerchantIndex].id, data)
		.subscribe(
		  (response) => {
			this.modalService.dismissAll();
			this.data[this.currentMerchantIndex].user_id = response.user.id;
			this.gridApi.refreshCells();
			this.alertify.success(this.translate.instant('general.success'));
		  },
		  (error) => {
			if(error.status == 422 && error.error.errors.email){
				this.repeatedEmail = true;
			} else {
				this.alertify.error(this.translate.instant('general.error'));
			}
		  }
		);
	}

	validCreateUser() {
		if ((this.email) && this.password && this.confirmPassword && ( this.password === this.confirmPassword )) {
			this.error = false;
			return true;
		}
		this.error = true;
		return false;
	}

	cleanModal(){
		this.email = undefined;
		this.password = undefined;
		this.confirmPassword = undefined;
	}

}
