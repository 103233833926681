<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faUsers" class="mr-2 h2"></fa-icon></li>
      <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li class="breadcrumb-item" aria-current="page"><a routerLink="/admin/admins" class="text-primary-color-3">{{ "admin.admins" | translate }}</a></li>
    </ol>
  </nav> 
  <div class="d-flex justify-content-center pt-3">
      <div class="col-md-10">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
          <div class="d-flex justify-content-center">
            <div class="col-md-6 text-center">
              <h5>{{ "admin.admins" | translate }}</h5>
            </div>
          </div>
          <div class="form-group">
            <label for="name">{{ "user.form.name" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{ "user.errors.name" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="user.email">{{ "user.form.email" | translate }}:</label>
            <input class="form-control" [readonly]="view() || edit()" name="email" type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{ "user.errors.email" | translate }}</div>
                <div *ngIf="f.email.errors.email">{{ "user.errors.email_valid" | translate }}</div>
            </div>
          </div>
          <div *ngIf="create()">
            <div class="form-group">
              <label for="user.password">{{ "user.form.password" | translate }}:</label>
              <input class="form-control" name="password" type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">{{ "user.errors.password" | translate }}</div>
              </div>
            </div>
            <div class="form-group">
              <label for="repeat-password">{{ "user.form.confirm_password" | translate }}:</label>
              <input class="form-control" name="repeat-password" type="password" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" pattern="{{ f.password.value }}">
              <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">{{ "user.errors.confirm_password" | translate }}</div>
                <div *ngIf="f.confirmPassword.errors.pattern">{{ "user.change_password.confirmation_password_failed" | translate }}</div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-3" routerLink="/admin/admins">{{ "form.cancel" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="edit()" >{{ "form.update" | translate }}</button>
          </div>
        </form>
      </div>
  </div>
