<ag-grid-angular 
			style="width: 100%; height: 520px;"
			class="ag-theme-alpine"
			[rowData]="data"
			[pagination]="true"
			[paginationPageSize]="10"
			[gridOptions]="gridOptions"
			(gridReady)="onGridReady($event)"
			[localeText]="gridLocale"
			>
</ag-grid-angular>