import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ListService } from 'src/app/shared/list-service.service';
import { ListComponent } from 'src/app/shared/list/list.component';
import { AG_GRID_LOCALE_EN } from '../../shared/list/locale/en.locale';
import { ActionsCellRenderer } from 'src/app/shared/list/renderer/actions-cell.renderer';
import { TranslateService } from '@ngx-translate/core';
import { CategoryService } from '../category.service';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css'],
  providers: [ListService, DecimalPipe]
})
export class CategoryListComponent extends ListComponent implements OnInit {
	faClipboard = faClipboard;
  private gridApi;
	public gridOptions: any;
	public gridLocale;

  constructor(
		public service: CategoryService,
		public listService: ListService,
		public translateService: TranslateService
	) {
		super(service, listService);
		this.setObject('category');
	}

	ngOnInit() {
		this.gridLocale = AG_GRID_LOCALE_EN;
		this.gridOptions = {
			defaultColDef: {
				resizable: true,
			},
			columnDefs: [
				{ headerName: '#', field: 'id', sortable: true, width: 60 },
				{ headerName: 'category.name', field: 'name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'category.rgb', field: 'background_rgb', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
					headerName: 'list.actions',
					field: 'id',
					cellRenderer: 'actionsCellRenderer',
					cellRendererParams: {
						actions: [
							
							{
								icon: 'ver_active2x.png',
								action: (id) => {
									this.show(id);
								}
							},
							{
								icon: 'icon_editar2x.png',
								action: (id) => {
									this.edit(id);
								}
							},
							{
								icon: 'icon_borrar2x.png',
								action: (id) => {
									this.delete(id);
								}
							}
						]
					},
          			headerValueGetter: this.localizeHeader.bind(this)
				}
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
    	this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}

}
