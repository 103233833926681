import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponService extends CrudService {

  merchantId;
  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('coupon');
  }

  all(): Observable<any> {
    return this.http.get(this.apiUrl + this.object+'/list/' + this.merchantId);
  }
}