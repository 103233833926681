import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { faIndustry } from '@fortawesome/free-solid-svg-icons';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FormComponent } from 'src/app/shared/form/form.component';
import { SponsorService } from '../sponsor.service';

@Component({
  selector: 'app-sponsor-form',
  templateUrl: './sponsor-form.component.html',
  styleUrls: ['./sponsor-form.component.css']
})
export class SponsorFormComponent extends FormComponent implements OnInit {
  faIndustry = faIndustry;
  image;
  urlImage: any;
  fileToUpload: File;

  constructor(
    public service: SponsorService,
    public imageCompress: NgxImageCompressService) {
      super(service);
    }

    ngOnInit() {
      super.ngOnInit();
      this.redirect = ['/sponsor'];

      this.form = this.formBuilder.group({
        name: ['', [Validators.required,Validators.maxLength(255)]],
        website_url: ['', [Validators.maxLength(255)]],
        active: ['1'],
        fixed: ['0'],
      });  
    }

    dataToForm(data) {
      this.urlImage = (data.image_url != null) ? data.cloudinary_url + data.image_url : null;
      return {
        name: data.name,
        website_url: data.website_url,
        active: '' + data.active,
        fixed: '' + data.fixed,
      };
    }
  
    formToData() {
      const formData: FormData = new FormData();
      if(this.fileToUpload != null){
        formData.append('image', this.fileToUpload);
      }
      
      formData.append('name', this.f.name.value);
      formData.append('website_url', this.f.website_url.value);
      formData.append('active', this.f.active.value);
      formData.append('fixed', this.f.fixed.value);

      this.data = formData;

    }

    fileChangeEvent(event: any): void {
      const FileSize = event.target.files.item(0).size / 1024 / 1024; // in MB
      if (FileSize > 10) {
        event.value = '';
        this.alertify.error('El tamaño del archivo excede los 10 MB');
        return;
      }
      this.fileToUpload = event.target.files.item(0);
    }

    validation(){
      if(this.fileToUpload == null && this.create()){
        this.alertify.error(this.translate.instant('general.form.upload_image_required'));
        return false;
      }

      return true;
    }

}
