import { Component, OnInit } from '@angular/core';
import { AuthService } from './../auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  newPassword:string;
  oldPassword:string;
  confirmPassword:string;
  oldPasswordError:boolean;
  newPasswordError:boolean;
  constructor(public auth:AuthService,
    public modalService:NgbModal,
    public alertify:AlertifyService,
    public router: Router,) { }

  ngOnInit() {
  }

  open(content){
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.cleanModal();
    }, (reason) => {
      this.cleanModal();
    });
  }

  validModal(){
    if(!this.oldPassword || this.oldPassword.length <= 0){
      this.oldPasswordError = true;
    }else{
      this.oldPasswordError = false;
    }
    
    if(!this.newPassword || !this.confirmPassword || ( this.newPassword != this.confirmPassword )){
      this.newPasswordError = true;
    }else{
      this.newPasswordError = false;
    }
    
    if(this.newPasswordError || this.oldPasswordError){
      return false;
    }
    return true;
  }

  changePassword(){
    if(!this.validModal()){
      return;
    }
    this.auth.changePasswordByUser(this.oldPassword,this.newPassword)
    .subscribe( 
      (response) => {
        this.modalService.dismissAll();
        this.alertify.success("Se cambio la contraseña del usuario correctamente");
        this.auth.logout();
        this.router.navigate(['/login']);
      },
      (error) => {
        this.alertify.error(error || "Ocurrió un error");
      }
    );
    

  };

  cleanModal(){
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.oldPasswordError = false;
    this.newPasswordError = false;
  }

}
