import { Component, OnInit, Input, NgZone } from '@angular/core';
import { ListService } from '../../../shared/list-service.service';
import { DecimalPipe } from '@angular/common';
import { ListComponent } from '../../../shared/list/list.component';
import { CouponService } from '../coupon.service';
import { AG_GRID_LOCALE_EN } from '../../../shared/list/locale/en.locale';
import { ActionsCellRenderer } from '../../../shared/list/renderer/actions-cell.renderer';
import { AuthService } from 'src/app/auth-module/auth.service';

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.css'],
  providers: [ListService, DecimalPipe]
})
export class CouponListComponent extends ListComponent implements OnInit {

  private gridApi;
  public gridOptions: any;
  public gridLocale;
  @Input() visible: boolean;

  constructor(
    private zone: NgZone,
    public service: CouponService,
    public listService: ListService,
    public auth: AuthService) {
    super(service, listService);
    this.setObject('coupon');
  }

  ngOnInit() {
    if(this.auth.currentUserValue.user.role == 'Merchant'){
			this.service.merchantId = this.auth.currentUserValue.user.merchant.id;
		}else{
			this.service.merchantId = this.route.firstChild.snapshot.paramMap.get('idMerchant');
		}
    this.gridLocale = AG_GRID_LOCALE_EN;
    this.gridOptions = {
      columnDefs: [
        { headerName: '#', field: 'id', sortable: true, width: 60 },
        { headerName: 'coupon.form.title', field: 'title', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
        { headerName: 'coupon.form.expiration', field: 'expiration', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
        { headerName: 'coupon.redemption_count', field: 'redemption_count', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
        {
          headerName: 'coupon.form.status',
          field: 'status',
          sortable: true,
          cellRenderer: params => {
            if (params.value) {
              return this.translate.instant('coupon.active');
            } else {
              return this.translate.instant('coupon.inactive');
            }
          },
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          headerName: 'list.actions',
          field: 'id',
          cellRenderer: 'actionsCellRenderer',
          cellRendererParams: {
            actions: [
              {
                icon: 'ver_active2x.png',
                action: (id) => {
                  this.zone.run(() => {
                    this.show(id);
                  });
                }
              },
              {
                icon: 'icon_editar2x.png',
                action: (id) => {
                  this.zone.run(() => {
                    this.edit(id);
                  });
                }
              },
              {
                icon: 'icon_borrar2x.png',
                action: (id) => {
                  this.delete(id);
                }
              }
            ]
          },
                headerValueGetter: this.localizeHeader.bind(this)
        }
      ],
      frameworkComponents: {
        'actionsCellRenderer': ActionsCellRenderer
      }
    };

    this.loadData();
  }

  show(id){
    this.router.navigate(['/'+this.object + '/'+this.service.merchantId +'/view/'+id]);
  }

  edit(id){
    this.router.navigate(['/'+this.object + '/'+this.service.merchantId +'/edit/'+id]);
  }

  onFilterTextBoxChanged(value): void {
    this.gridApi.setQuickFilter(value);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.refreshHeader();
    if(this.visible)
      this.sizeColumns();
  }

  sizeColumns(){
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}

}
