import { Component, OnInit, PipeTransform } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { DecimalPipe } from '@angular/common';
import { ListService } from 'src/app/shared/list-service.service';
import { ListComponent } from 'src/app/shared/list/list.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth-module/auth.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
  providers: [ListService, DecimalPipe]
})

export class UserListComponent extends ListComponent implements OnInit {
  selectedUser: any;
  error: boolean;
  password: string;
  confirmPassword: string;
  modalPassword: Promise<void>;
  constructor(
    public userService: UserService,
    public userListService: ListService,
    public modalService: NgbModal,
    public auth: AuthService
    ) {
      super(userService, userListService);
      this.setObject('user');
      this.setConfirmDeleteMessage(this.translate.instant('general.list.confirm_question_delete'));
      this.setConfirmationDeleteMessage(this.translate.instant('general.list.confirmation_message'));

      this.userListService.matches = function(data: any, term: string, pipe: PipeTransform) {
        return data.name.toLowerCase().includes(term)
        || data.email.toLowerCase().includes(term);
      };
    }

    open(content, id) {
      this.selectedUser = id;
      this.modalPassword = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.cleanModal();
      }, (reason) => {
        this.cleanModal();
      });
    }

    validModal() {
      if (this.password && this.confirmPassword && ( this.password === this.confirmPassword )) {
        this.error = false;
        return true;
      }
      this.error = true;
      return false;
    }

    changePassword() {
      if (!this.validModal()) {
        return;
      }
      this.auth.changePasswordByAdmin(this.selectedUser, this.password)
      .subscribe(
        (response) => {
          this.modalService.dismissAll();
          this.alertify.success(this.translate.instant('general.success'));
        },
        (error) => {
          this.alertify.error(this.translate.instant('general.error'));
        }
      );
    }

    cleanModal() {
      this.selectedUser = '';
      this.password = '';
      this.confirmPassword = '';
      this.error = false;
    }
}
