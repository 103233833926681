import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { FormComponent } from '../../../shared/form/form.component';
import { LocationService } from '../location.service';
import { Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.css']
})
export class LocationFormComponent  extends FormComponent implements OnInit {
  faHome = faHome;
  merchantId;
  sales_agents = [];
  latStart = 37.09024;
  lngStart = -95.712891;
  lat = 0;
  lng = 0;
  role: any;

  map: any;
	mapClickListener: any;
  @ViewChild('search')
	public searchElementRef: ElementRef;
	address: string;
	private geoCoder;

  constructor(
    public service: LocationService,
    public auth: AuthService,
    private zone: NgZone,
		private mapsAPILoader: MapsAPILoader) {
      super(service);
      this.role = this.auth.currentUserValue.user.role;

      if(this.auth.currentUserValue.user.role == 'Merchant'){
        this.merchantId = this.auth.currentUserValue.user.merchant.id;
      }else{
        this.merchantId = this.route.firstChild.snapshot.paramMap.get('idMerchant');
      }
    }

  ngOnInit() {
    super.ngOnInit();
    if(this.auth.currentUserValue.user.role == 'Merchant'){
      this.redirect = ['/setup/loc'];
		}else{
      this.redirect = ['/setup/' + this.merchantId+'/loc'];
		}

    this.lat = this.latStart;
    this.lng = this.lngStart;
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        address: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        country: ['', Validators.required],
        zip: ['', Validators.required],
        phone: ['', [Validators.required, Validators.pattern]],
        email: ['', [Validators.email]],
        location_number: ['', Validators.required],
        sales_agents_id: ['', Validators.required],
        status: ['1', Validators.required],
      });

      this._setAutoCompleteSearchBox();
  }

  private _setAutoCompleteSearchBox() {
		//load Places Autocomplete
		this.mapsAPILoader.load().then(() => {
			// this.setCurrentLocation();
			this.geoCoder = new google.maps.Geocoder;
			const options = {
			};
			let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, options);
			autocomplete.addListener("place_changed", () => {
				this.zone.run(() => {
					//get the place result
					let place: google.maps.places.PlaceResult = autocomplete.getPlace();
					//verify result
					if (place.geometry === undefined || place.geometry === null) {
						return;
					}

					//set latitude, longitude and zoom
					this.lat = place.geometry.location.lat();
					this.lng = place.geometry.location.lng();

          this.latStart = place.geometry.location.lat();
					this.lngStart = place.geometry.location.lng();
				});
			});
		});
	}

  getExtras(){
    this.service.agents().subscribe( (response) => {
      this.sales_agents = response;
    }, (error) => {
      this.alertify.error('No fue posible obtener los agentes de venta');
    });
  }

  dataToForm(data) {
    this.latStart = data.latitude;
    this.lngStart = data.longitude;
    this.lat = data.latitude;
    this.lng = data.longitude;
    return {
      name: data.name,
      address: data.address,
      city: data.city,
      state: data.state,
      country: data.country,
      zip: data.zip,
      phone: data.telephone,
      email: data.email,
      location_number: data.location_number,
      sales_agents_id: data.sales_agents_id,
      status: ''+data.status,
    };
  }

  formToData() {
    this.data = {
      name: this.f.name.value,
      address: this.f.address.value,
      city: this.f.city.value,
      state: this.f.state.value,
      country: this.f.country.value,
      zip: this.f.zip.value,
      telephone: this.f.phone.value,
      email: this.f.email.value,
      location_number: this.f.location_number.value,
      sales_agents_id: this.f.sales_agents_id.value,
      status: this.f.status.value,
      latitude : this.lat,
      longitude: this.lng,
      merchant_id : this.merchantId
    };
  }

  addMarker($event){
    console.log($event);
    if(!this.view()){
      //this.lat = lat;
      //this.lng = lng;
    }
  }

  public mapReadyHandler(map: google.maps.Map): void {
		this.map = map;
		this.mapClickListener = this.map.addListener('click', (e: google.maps.KmlMouseEvent) => {
			this.zone.run(() => {
				this.lat = e.latLng.lat();
				this.lng = e.latLng.lng();

        this.latStart = e.latLng.lat();
				this.lngStart = e.latLng.lng();
				
			});
		});
	}

}
