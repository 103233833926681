import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { FormComponent } from 'src/app/shared/form/form.component';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-notification-general-form',
  templateUrl: './notification-general-form.component.html',
  styleUrls: ['./notification-general-form.component.css']
})
export class NotificationGeneralFormComponent extends FormComponent implements OnInit {
  faEnvelopeOpenText = faEnvelopeOpenText;
  constructor(
    public service: NotificationService) {
      super(service);
    }

    ngOnInit() {
      super.ngOnInit();
      this.redirect = ['/notification'];

      this.form = this.formBuilder.group({
        title: ['', [Validators.required,Validators.maxLength(255)]],
        message: ['', [Validators.required,Validators.maxLength(255)]]
      });  
    }
  
    dataToForm(data) {
      return {
        title: data.title,
        message: data.message
      };
    }
  
    formToData() {
      this.data = {
        title: this.f.title.value,
        message: this.f.message.value,
      };
    }
  
    save() {
      this.service.general(this.data).subscribe((response) => {
        this.alertify.success(this.translate.instant('general.form.success_save'));
        this.router.navigate(this.redirect);
      }, (error) => {
        this.alertify.error(this.translate.instant('general.error'));
      });
    }

}
