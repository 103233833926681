<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faHome" class="mr-2 h2"></fa-icon></li>
      <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li class="breadcrumb-item" aria-current="page"><a routerLink="/merchant" class="text-primary-color-3">{{ "admin.merchants" | translate }}</a></li>
    </ol>
  </nav> 
  <div class="d-flex justify-content-center pt-3">
      <div class="col-md-10">
        <ngx-loading [show]="loading" ></ngx-loading>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
          <div class="d-flex justify-content-center">
            <div class="col-md-6 text-center">
              <h5>{{ "admin.merchants" | translate }}</h5>
            </div>
          </div>
          <div class="form-group">
            <label for="name">{{ "user.form.name" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f.name?.errors }">
            <div *ngIf="submitted && f.name?.errors" class="invalid-feedback">
                <div *ngIf="f.name?.errors.required">{{ "merchant.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="description">{{"merchant.form.description" | translate}}:</label>
            <textarea 
                class="form-control"
                [readonly]="view()"
                formControlName="description"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && f.description?.errors }"
                cols="30"
                rows="10">
            </textarea>
            <div *ngIf="submitted && f.description?.errors" class="invalid-feedback">
                <div *ngIf="f.description?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="category">{{"merchant.form.category" | translate}}:</label>
            <select class="form-control" name="category" formControlName="category" (change)="changeCategory()" [ngClass]="{ 'is-invalid': submitted && f.category?.errors }">
              <option></option>
                <option *ngFor="let category of categories"  [ngValue]="category.id">{{category.name}}</option>
            </select>
            <div *ngIf="submitted && f.category?.errors" class="invalid-feedback">
                <div *ngIf="f.category?.errors.required">{{ "merchant.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="subcategory">{{"merchant.form.subcategories" | translate}}:</label>
            <select class="form-control" name="subcategory" formControlName="subcategory" [ngClass]="{ 'is-invalid': submitted && f.subcategory?.errors }">
              <option></option>
                <option *ngFor="let subcategory of subcategories"  [ngValue]="subcategory.id">{{subcategory.name}}</option>
            </select>
            <div *ngIf="submitted && f.subcategory?.errors" class="invalid-feedback">
                <div *ngIf="f.subcategory?.errors.required">{{ "merchant.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="facebook">Facebook:</label>
            <input class="form-control" [readonly]="view()" formControlName="facebook" type="text" [ngClass]="{ 'is-invalid': submitted && f.facebook?.errors }">
            <div *ngIf="submitted && f.facebook?.errors" class="invalid-feedback">
                <div *ngIf="f.facebook.errors?.required">{{ "merchant.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="instagram">Instagram:</label>
            <input class="form-control" [readonly]="view()" formControlName="instagram" type="text" [ngClass]="{ 'is-invalid': submitted && f.instagram?.errors }">
            <div *ngIf="submitted && f.instagram?.errors" class="invalid-feedback">
                <div *ngIf="f.instagram.errors?.required">{{ "merchant.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="merchant_url">{{"merchant.form.website" | translate}}:</label>
            <input class="form-control" [readonly]="view()" formControlName="merchant_url" type="text" [ngClass]="{ 'is-invalid': submitted && f.merchant_url?.errors }">
            <div *ngIf="submitted && f.merchant_url?.errors" class="invalid-feedback">
                <div *ngIf="f.merchant_url.errors?.required">{{ "merchant.errors.required" | translate }}</div>
            </div>
          </div>

          <!-- User -->
          <div class="form-group">
            <label for="user.email">{{ "user.form.email" | translate }}:</label>
            <input class="form-control" [readonly]="view() || edit()" name="email" type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{ "user.errors.email" | translate }}</div>
                <div *ngIf="f.email.errors.email">{{ "merchant.errors.required" | translate }}</div>
            </div>
          </div>
          <div *ngIf="create()">
            <div class="form-group">
              <label for="user.password">{{ "user.form.password" | translate }}:</label>
              <input class="form-control" name="password" type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && passwordError }">
              <div *ngIf="submitted && passwordError" class="invalid-feedback">
                <div>{{ "user.change_password.confirmation_password_failed" | translate }}</div>
              </div>
            </div>
            <div class="form-group">
              <label for="repeat-password">{{ "user.form.confirm_password" | translate }}:</label>
              <input class="form-control" name="repeat-password" type="password" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && passwordError }" pattern="{{ f.password.value }}">
              <div *ngIf="submitted && passwordError" class="invalid-feedback">
                <div>{{ "user.change_password.confirmation_password_failed" | translate }}</div>
              </div>
            </div>
          </div>

          <!-- Tags -->
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="tag">{{"merchant.form.tags" | translate}}:</label>
                <div class="row" *ngIf="!view()">
                  <div class="col-8">
                    <ngx-select-dropdown
                      (change)="tagSelect(m)" #m [config]="config" 
                      [options]="tags">
                    </ngx-select-dropdown>
                  </div>
                  <div class="col-4">
                    <button type="button" class="btn btn-primary" (click)="addTag()">{{ "merchant.add" | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2 col-md-3  col-sm-4 mb-2" *ngFor="let tagS of tagsSelected" >
              <small>
                <li class="list-group-item p-1 text-center">
                  <button *ngIf="!view()" type="button" class="btn btn-default btn-sm p-0" (click)="removeTag(tagS.id)">
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                  {{tagS.name}} 
                </li>
              </small>
            </div>
          </div>

          <!-- LOGO -->
          <hr>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group mt-2" >
                <label for="zone">Logo:</label>
                <br>
                <input type="file" (change)="fileChangeEvent($event,0)" *ngIf="!view()"/>
                <div class="row" *ngIf="fileVisible[0]">
                    <div class="col-md-5">
                        <label *ngIf="showCropper" for="">{{ "merchant.form.preview" | translate }}</label>
                        <br>
                        <img *ngIf="showCropper" [src]="croppedImage" width="400px" />
                        <br>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6" *ngIf="images[0]">
              <img [src]="images[0]" width="200px" />
              <br>
              <div class="row align-items-center mt-2">
                <div class="col-auto">
                  <button type="button" class="btn btn-default p-0" (click)="removeImg(0)">
                    <fa-icon class="h5" [icon]="faTrash"></fa-icon>
                  </button>
                </div>
                <div class="col-auto">
                  <a class="text-dark h5" [href]="images[0]" target="_blank"><fa-icon [icon]="faCloudDownload"></fa-icon></a>
                </div>
              </div>
            </div>
          </div>

          <!-- PHOTO 
          <hr>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group mt-2" >
                <label for="zone">Photo:</label>
                <br>
                <input type="file" (change)="fileChangeEvent($event,1)" *ngIf="!view()"/>
                <div class="row" *ngIf="fileVisible[1]">
                    <div class="col-md-5">
                        <label *ngIf="showCropper" for="">{{ "merchant.form.preview" | translate }}</label>
                        <br>
                        <img *ngIf="showCropper" [src]="croppedImage" width="400px" />
                        <br>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6" *ngIf="images[1]">
              <img [src]="images[1]" width="200px" />
              <br>
              <div class="row align-items-center mt-2">
                <div class="col-auto">
                  <button type="button" class="btn btn-default p-0" (click)="removeImg(1)">
                    <fa-icon class="h5" [icon]="faTrash"></fa-icon>
                  </button>
                </div>
                <div class="col-auto">
                  <a class="text-dark h5" [href]="images[1]" target="_blank"><fa-icon [icon]="faCloudDownload"></fa-icon></a>
                </div>
              </div>
            </div>
          </div>
          -->
          
          <!-- IMAGE 1 -->
          <hr>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label for="zone">{{ ("merchant.form.image" | translate)+" 1" }}:</label>
                <br>
                <input type="file" (change)="fileChangeEvent($event,2)" *ngIf="!view()"/>
                <div class="row" *ngIf="fileVisible[2]">
                    <div class="col-md-5">
                        <label *ngIf="showCropper" for="">{{ "merchant.form.preview" | translate }}</label>
                        <br>
                        <img *ngIf="showCropper" [src]="croppedImage" width="400px" />
                        <br>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6" *ngIf="images[2]">
              <img [src]="images[2]" width="200px" />
              <br>
              <div class="row align-items-center mt-2">
                <div class="col-auto">
                  <button type="button" class="btn btn-default p-0" (click)="removeImg(2)">
                    <fa-icon class="h5" [icon]="faTrash"></fa-icon>
                  </button>
                </div>
                <div class="col-auto">
                  <a class="text-dark h5" [href]="images[2]" target="_blank"><fa-icon [icon]="faCloudDownload"></fa-icon></a>
                </div>
              </div>
            </div>
          </div>

          <!-- IMAGE 2 -->
          <hr>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group" >
                <label for="zone">{{ ("merchant.form.image" | translate)+" 2" }}:</label>
                <br>
                <input type="file" (change)="fileChangeEvent($event,3)" *ngIf="!view()" />
                <div class="row" *ngIf="fileVisible[3]">
                    <div class="col-md-5">
                        <label *ngIf="showCropper" for="">{{ "merchant.form.preview" | translate }}</label>
                        <br>
                        <img *ngIf="showCropper" [src]="croppedImage" width="400px" />
                        <br>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6" *ngIf="images[3]">
              <img [src]="images[3]" width="200px" />
              <br>
              <div class="row align-items-center mt-2">
                <div class="col-auto">
                  <button type="button" class="btn btn-default p-0" (click)="removeImg(3)">
                    <fa-icon class="h5" [icon]="faTrash"></fa-icon>
                  </button>
                </div>
                <div class="col-auto">
                  <a class="text-dark h5" [href]="images[3]" target="_blank"><fa-icon [icon]="faCloudDownload"></fa-icon></a>
                </div>
              </div>
            </div>
          </div>

            <!-- IMAGE 3 -->
            <hr>
            <div class="row">
              <div class="col-xl-6">
                <div class="form-group" >
                  <label for="zone">{{ ("merchant.form.image" | translate)+" 3" }}:</label>
                  <br>
                  <input type="file" (change)="fileChangeEvent($event,4)" *ngIf="!view()" />
                  <div class="row" *ngIf="fileVisible[4]">
                      <div class="col-md-5">                          
                          <button type="button" class="btn btn-success mr-4" *ngIf="showCropper" (click)="endCrop()">{{ "merchant.form.accept" | translate }}</button>
                      </div>
                      <div class="col-md-5">
                          <label *ngIf="showCropper" for="">{{ "merchant.preview" | translate }}</label>
                          <br>
                          <img *ngIf="showCropper" [src]="croppedImage" width="400px" />
                          <br>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6" *ngIf="images[4]">
                <img [src]="images[4]" width="200px" />
                <br>
                <div class="row align-items-center mt-2">
                  <div class="col-auto">
                    <button type="button" class="btn btn-default p-0" (click)="removeImg(4)">
                      <fa-icon class="h5" [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                  <div class="col-auto">
                    <a class="text-dark h5" [href]="images[4]" target="_blank"><fa-icon [icon]="faCloudDownload"></fa-icon></a>
                  </div>
                </div>
              </div>
            </div>
          
          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-3" routerLink="/merchant">{{ "form.cancel" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="edit()" >{{ "form.update" | translate }}</button>
          </div>
        </form>
      </div>
  </div>
