import { Component, OnInit } from '@angular/core';
import { FormComponent } from '../../../shared/form/form.component';
import { CouponService } from '../coupon.service';
import { Validators } from '@angular/forms';
import { LocationService } from '../location.service';
import { MerchantService } from '../../merchant.service';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-coupon-form',
  templateUrl: './coupon-form.component.html',
  styleUrls: ['./coupon-form.component.css']
})
export class CouponFormComponent extends FormComponent implements OnInit {
  faHome = faHome;
  faCalendarAlt = faCalendarAlt;
  merchantId;
  merchant;
  locations = [];
  locationsSelected = [];
  role: any;

  constructor(
    public service: CouponService,
    public serviceLocation: LocationService,
    public serviceMerchant: MerchantService,
    public auth: AuthService) {
      super(service);
      this.role = this.auth.currentUserValue.user.role;

      if(this.auth.currentUserValue.user.role == 'Merchant'){
        this.merchantId = this.auth.currentUserValue.user.merchant.id;
      }else{
        this.merchantId = this.route.firstChild.snapshot.paramMap.get('idMerchant');
      }
    }

  ngOnInit() {
    
    super.ngOnInit();

    if(this.auth.currentUserValue.user.role == 'Merchant'){
      this.redirect = ['/setup/cou'];
		}else{
      this.redirect = ['/setup/' + this.merchantId+'/cou'];
		}
    
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      subtitle: ['', Validators.required],
      description: ['', Validators.required],
      expiration: ['', Validators.required],
      redemption: ['', [Validators.required]],
      cashier: ['', [Validators.required]],
      disclaimer: ['', Validators.required],
      code: ['', Validators.required],
      all_locations: ['0', Validators.required],
      // available: ['', Validators.required],
      status: ['1', Validators.required],
    });
  }

  getExtras(){
    this.serviceLocation.getdd(this.merchantId).subscribe( (response) => {
      this.locations = response;
      if(!this.create()){
        this.hasCheck();
      }
    }, (error) => {
      this.alertify.error('No fue posible obtener las localizaciones');
    });
    this.serviceMerchant.get(this.merchantId).subscribe( (response) => {
      this.merchant = response;
    }, (error) => {
      this.alertify.error('No fue posible obtener el comerciante');
    });
  }

  dataToForm(data) {
    return {
      title: data.title,
      subtitle: data.subtitle,
      description: data.description,
      expiration: this.stringToDate(data.expiration),
      redemption: data.redemption_note,
      cashier: data.cashier_note,
      disclaimer: data.disclaimer,
      code: data.code,
      all_locations: ''+data.all_locations,
      // available: data.available,
      status: ''+data.status,
    };
  }
  
  formToData() {
    this.data = {
      title: this.f.title.value,
      subtitle: this.f.subtitle.value,
      description: this.f.description.value,
      expiration: this.dateToString(this.f.expiration.value),
      redemption_note: this.f.redemption.value,
      cashier_note: this.f.cashier.value,
      disclaimer: this.f.disclaimer.value,
      code: this.f.code.value,
      all_locations: this.f.all_locations.value,
      locations: this.f.all_locations.value == 0 ? this.locationsSelected :[],
      // available: this.f.available.value,
      status: this.f.status.value,
      merchant_id: this.merchantId,
    };
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid || !this.validation()) {
      this.alertify.error(this.translate.instant('general.form.complete_fields'));
      return;
    }
    
    if(this.f.all_locations.value === "0" && this.locationsSelected.length == 0){
      this.alertify.error(this.translate.instant('coupon.errors.location'));
      return;
    }

    this.formToData();

    if (this.create()) {
      this.save();
    }

    if (this.edit()) {
      this.update();
    }
  }


  validCheck(ev){ 
    let val = parseInt(ev.currentTarget.defaultValue);
    if(ev.currentTarget.checked){
      this.locationsSelected.push(val);
    }else{
      this.locationsSelected = this.arrayRemove(this.locationsSelected, val);
    }
  }


  arrayRemove(arr, value) {   
    return arr.filter(function(ele){ 
        return ele != value; 
    });
  }

  hasCheck(){
    if(this.data){
      this.locations.forEach(locg => {
        this.data.locations.forEach(locd => {
           if(locd.id === locg.id){
            locg.checked = true;
              if(!this.locationsSelected.includes(locg.id)){
                this.locationsSelected.push(locg.id);
              }
           }
        });
      });
    }
  }
}
