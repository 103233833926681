<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faIndustry" class="mr-2 h2"></fa-icon></li>
      <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
      <li class="breadcrumb-item" aria-current="page"><a routerLink="/sponsor" class="text-primary-color-3">{{ "admin.sponsor" | translate }}</a></li>
    </ol>
  </nav> 
  <div class="d-flex justify-content-center pt-3">
      <div class="col-md-10">
        <ngx-loading [show]="loading" ></ngx-loading>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
          <div class="d-flex justify-content-center">
            <div class="col-md-6 text-center">
              <h5>{{ "admin.sponsor" | translate }}</h5>
            </div>
          </div>
          <div class="form-group">
            <label for="name">{{ "sponsor.name" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{ "general.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="website_url">{{ "sponsor.web" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="website_url" type="text" [ngClass]="{ 'is-invalid': submitted && f.website_url.errors }">
            <div *ngIf="submitted && f.website_url.errors" class="invalid-feedback">
                <div *ngIf="f.website_url.errors.required">{{ "general.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="fixed">{{ "sponsor.fixed" | translate }}:</label>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null"
                    formControlName="fixed" type="radio" name="fixed" id="fixed1"
                    value="1">
                <label class="form-check-label" for="fixed1">{{ "form.yes" | translate
                    }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null"
                    formControlName="fixed" type="radio" name="fixed" id="fixed2"
                    value="0">
                <label class="form-check-label" for="fixed2">{{  "form.no" | translate
                    }}</label>
            </div>
            <div class="fixed-label">
                * If you check the fixed box, it will be disabled for the sponsor that currently has it active
            </div>
          </div>
          <div class="form-group">
            <label for="active">{{ "sponsor.active" | translate }}:</label>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null"
                    formControlName="active" type="radio" name="active" id="active1"
                    value="1">
                <label class="form-check-label" for="price_by_person1">{{ "form.yes" | translate
                    }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [attr.disabled]="view() ? '' : null"
                    formControlName="active" type="radio" name="active" id="active2"
                    value="0">
                <label class="form-check-label" for="active2">{{  "form.no" | translate
                    }}</label>
            </div>
            
          </div>
          <div class="row">
            <div class="col-auto">
              <div class="form-group mt-2" >
                <label for="zone">{{ "reward.form.image" | translate }}:</label>
                <br>
                <input type="file" (change)="fileChangeEvent($event)" *ngIf="!view()"/>
              </div>
            </div>
            <div class="col-auto" *ngIf="urlImage">
              <img [src]="urlImage" width="200px" />
              <br>
              <div class="row align-items-center mt-2">
                <div class="col-auto" *ngIf="!view()">
                  <button type="button" class="btn btn-default p-0" (click)="urlImage=''">
                    <fa-icon class="h5" [icon]="faTrash"></fa-icon>
                  </button>
                </div>
                <div class="col-auto">
                  <a class="text-dark h5" [href]="urlImage" target="_blank"><fa-icon [icon]="faCloudDownload"></fa-icon></a>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-3" routerLink="/sponsor">{{ "form.cancel" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="edit()" >{{ "form.update" | translate }}</button>
          </div>
        </form>
      </div>
  </div>





