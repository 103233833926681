import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('role');
  }

  getPermissions(id: string): Observable<any> {
    return this.http.get(this.apiUrl + this.object + '/permissions/' + id);
  }
}