import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../shared/crud.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SalesAgentService extends CrudService {

  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('sales-agent');
  }
}
