import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { TranslateService } from '@ngx-translate/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  appHome = ['/admin'];


  constructor(
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public alertify: AlertifyService,
    public translate: TranslateService
    ) { 
    }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin';
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.auth.login(this.f.username.value, this.f.password.value).subscribe((response) => {
      let role = response.user.role;

      localStorage.setItem('permissions', JSON.stringify(response.user));
      this.auth.setPermissions(response.user);
      if(role == 'Merchant'){
        this.returnUrl = '/setup/loc';
      }else if(role == 'Administrator'){
      }else {
        this.auth.logout();
        this.alertify.error(this.translate.instant('auth.error_login'));
      }
      this.router.navigate([this.returnUrl]);
      this.alertify.success(this.translate.instant('auth.success_login'));

      
    }, (error) => {
      this.alertify.error(this.translate.instant('auth.error_login'));
    });
  }

}
